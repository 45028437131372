import ResidenceDetailDialog from 'components/Residences/ResidenceDetailDialog/ResidenceDetailDialog';
import ResidencesTable from 'components/Residences/ResidencesTable/ResidencesTable';
import useResidencesColumns from 'hooks/useResidencesColumns';
import { useCallback, useState } from 'react';
import { IResidence } from 'types/api/residences';

/**
 * Renders the Residences page
 */
export default function Residences() {
  const { residencesColumns } = useResidencesColumns();

  const [selectedResidence, setSelectedResidence] = useState<IResidence | undefined>(undefined);

  const openResidenceDetail = useCallback((residence: IResidence) => setSelectedResidence(residence), []);
  const closeResidenceDetail = useCallback(() => setSelectedResidence(undefined), []);

  return (
    <>
      {selectedResidence && (
        <ResidenceDetailDialog isOpen onClose={closeResidenceDetail} residence={selectedResidence} />
      )}
      <ResidencesTable columns={residencesColumns} onRowClick={openResidenceDetail} />
    </>
  );
}
