import React, { useMemo } from "react"

interface IOptions {
  value: string | number
  label: string
}
interface ISelect {
  label: string
  disabled?: boolean
  options: IOptions[]
  disabledOptions?: IOptions[]
  excludeOptions?: IOptions[]
  noOptions?: React.ReactNode
  value?: string | number
  onChange: (value: string) => void
  selectId: string
}

const Select: React.FC<ISelect> = ({
  label,
  options,
  disabledOptions = [],
  excludeOptions = [],
  disabled = false,
  value,
  onChange,
  selectId,
  noOptions,
}) => {
  const filteredOptions = useMemo(
    () => options.filter((opt) => !excludeOptions.some((exclOpt) => exclOpt.value === opt.value)),
    [options, excludeOptions]
  )
  return (
    <div className="w-full self-start">
      <>
        <label className="form-label" htmlFor={selectId}>
          {label}
        </label>
        {!options.length && noOptions ? (
          noOptions
        ) : (
          <select
            disabled={disabled}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="form-input"
            id={selectId}
          >
            {filteredOptions.map((option) => {
              const isDisabled = disabledOptions.some((disabledOpt) => disabledOpt.value === option.value)
              return (
                <option disabled={isDisabled} key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </select>
        )}
      </>
    </div>
  )
}

export default Select
