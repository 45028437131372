import React from "react"

interface ISearchBar {
  value: string
  onChange: (value: string) => void
  placeholder: string
}

const SearchBar: React.FC<ISearchBar> = ({ value, onChange, placeholder }) => (
  <input
    className="w-full form-input"
    type="search"
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
    placeholder={placeholder}
    value={value}
  />
)

export default SearchBar
