import Modal from "components/Shared/Modal/Modal"
import dayjs from "dayjs"
import { useAppealsQuery } from "features/queries"
import React, { useCallback, useMemo, useState } from "react"
import { FaArrowLeft, FaClock, FaPaperclip } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { IAppeal } from "types/api/appeals"
import { IResidence } from "types/api/residences"
import ResidenceAttachmentsComponent from "./ResidenceAttachmentsComponent"

interface IResidenceDetailDialogTitle {
  isAttachmentViewOpen: boolean
  openAttachmentView: () => void
  closeAttachmentView: () => void
  residence: IResidence
}
const ResidenceDetailDialogTitle: React.FC<IResidenceDetailDialogTitle> = ({
  isAttachmentViewOpen,
  openAttachmentView,
  closeAttachmentView,
  residence,
}) => {
  if (isAttachmentViewOpen)
    return (
      <div className="flex flex-row justify-between items-center ">
        <div className="flex flex-row items-center gap-2 text-sm font-medium">
          <FaPaperclip />
          <div className="flex flex-col items-start">
            <p>{residence.name}</p>
            <p className="underline">Allegati richiesti</p>
          </div>
        </div>
        <button
          onClick={closeAttachmentView}
          className="bluemazza-button flex flex-row items-center p-2 rounded text-sm font-medium "
        >
          <FaArrowLeft />
          <p className="pl-2">Torna indietro</p>
        </button>
      </div>
    )
  return (
    <div className="w-full flex flex-row items-center justify-between">
      <div className="flex flex-col items-start">
        Dettagli bando
        <p className="font-medium text-sm underline text-white">{residence.name}</p>
      </div>
      <div className="flex flex-row items-center justify-center">
        <button
          onClick={openAttachmentView}
          className="flex flex-row gap-2 items-center bluemazza-button text-sm font-medium rounded p-2 ml-2"
        >
          <FaPaperclip />
          Allegati richiesti
        </button>
      </div>
    </div>
  )
}

interface IResidenceDetailDialogBody {
  isAttachmentViewOpen: boolean
  residence: IResidence
  residenceAppeals: IAppeal[]
}

const ResidenceDetailDialogBody: React.FC<IResidenceDetailDialogBody> = ({
  isAttachmentViewOpen,
  residence,
  residenceAppeals,
}) => {
  const navigate = useNavigate()
  const navigateToResidenceAppeals = () => navigate(`/appeals?residence_id=${residence._id}`)
  if (isAttachmentViewOpen) return <ResidenceAttachmentsComponent residence={residence} />

  return (
    <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
      <div className="w-full">
        <label className="form-label" htmlFor="residence.name">
          Nome
        </label>

        <p className="font-medium text-md mt-0">{residence.name}</p>
      </div>
      <div className="w-full">
        <label className="form-label" htmlFor="residence.emails">
          Email associate
        </label>

        <div className="w-full flex flex-row items-center justify-between">
          <ul>
            {residence.emails.map((email) => (
              <li key={email}>{email}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="w-full flex flex-row items-center justify-between">
        <label className="form-label" style={{ marginBottom: 0 }}>
          Codici promozionali
        </label>
        <p className={`p-1 text-white text-xs rounded ${residence.promoCodeEnabled ? "bg-green-500" : "bg-red-500"}`}>
          {residence.promoCodeEnabled ? "Abilitati" : "Disabilitati"}
        </p>
      </div>
      <div className="w-full">
        <label className="form-label" htmlFor="passwordConfirmation">
          Appelli
        </label>
        <div className="w-full flex flex-row items-center justify-between">
          <p className="font-medium text-md mt-0">{residenceAppeals.length} totali</p>
          <p className="font-bold text-md text-green-500 underline mt-0">
            {
              residenceAppeals.filter(
                (appeal) => dayjs() < dayjs(appeal.expiryDate) && dayjs() > dayjs(appeal.acceptCandidacyFromDate)
              ).length
            }{" "}
            attivi
          </p>
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center mt-4">
        <button
          onClick={navigateToResidenceAppeals}
          className="bluemazza-button flex flex-row items-center gap-4"
          type="button"
        >
          <FaClock />
          <p>Visualizza gli appelli di questo bando</p>
        </button>
      </div>
    </div>
  )
}

interface IResidenceDetailDialog {
  isOpen: boolean
  onClose: () => void
  residence: IResidence
}

const ResidenceDetailDialog: React.FC<IResidenceDetailDialog> = ({ isOpen, onClose, residence }) => {
  const [isAttachmentViewOpen, setIsAttachmentViewOpen] = useState<boolean>(false)
  const { data: appeals = [] } = useAppealsQuery()

  const residenceAppeals = useMemo(
    () => appeals.filter((appeal) => appeal.residence_id === residence._id),
    [appeals, residence]
  )

  const openAttachmentView = useCallback(() => setIsAttachmentViewOpen(true), [])
  const closeAttachmentView = useCallback(() => setIsAttachmentViewOpen(false), [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <ResidenceDetailDialogTitle
          isAttachmentViewOpen={isAttachmentViewOpen}
          openAttachmentView={openAttachmentView}
          closeAttachmentView={closeAttachmentView}
          residence={residence}
        />
      }
    >
      <ResidenceDetailDialogBody
        isAttachmentViewOpen={isAttachmentViewOpen}
        residence={residence}
        residenceAppeals={residenceAppeals}
      />
    </Modal>
  )
}

export default ResidenceDetailDialog
