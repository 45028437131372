import React from 'react';
import { useUser } from 'context/userContext';
import { FaviconLoader } from 'components/Shared/Spinner/FaviconLoader';
import { IAuthMeResponse } from 'types/api/auth';
import { getUserInCookie } from 'utils/cookies';
import { useAuthUserQuery } from 'features/queries';

type AuthMiddlewareProps = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  const userInCookie = getUserInCookie();
  const { dispatch } = useUser();

  const onSuccess = (data: IAuthMeResponse) => dispatch({ type: 'SET_USER', payload: data });

  const { isLoading } = useAuthUserQuery({ onSuccess });

  if (isLoading && userInCookie) {
    return <FaviconLoader />;
  }

  return children;
};

export default AuthMiddleware;
