import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getApplicationsFn } from 'api/applicationsApi';
import { authMe } from 'api/authApi';
import { getAppealsFn, getResidencesFn } from 'api/residencesApi';
import { getUserProfileFn, getUsersFn } from 'api/usersApi';
import { IAppeal } from 'types/api/appeals';
import { IApplication } from 'types/api/applications/applications';
import { UserProfileSchema } from 'types/api/applications/userProfile';
import { IAuthMeResponse } from 'types/api/auth';
import { IResidence } from 'types/api/residences';
import { IUser } from 'types/api/users';

export enum ServerStateKeysEnum {
  USERS = 'users',
  RESIDENCES = 'residences',
  APPEALS = 'appeals',
  APPLICATIONS = 'applications',
  USER_PROFILE = 'userProfile',
  AUTH_USER = 'authUser',
}

export const useUsersQuery = () =>
  useQuery<IUser[]>([ServerStateKeysEnum.USERS], getUsersFn, {
    placeholderData: () => [],
  });

export const useResidencesQuery = () =>
  useQuery<IResidence[]>([ServerStateKeysEnum.RESIDENCES], getResidencesFn, {
    placeholderData: [],
  });

export const useAppealsQuery = () =>
  useQuery<IAppeal[]>([ServerStateKeysEnum.APPEALS], getAppealsFn, {
    placeholderData: () => [],
  });

export const useApplicationsQuery = () =>
  useQuery<IApplication[]>([ServerStateKeysEnum.APPLICATIONS], getApplicationsFn, {
    placeholderData: () => [],
  });

export const useUserProfileQuery = (userId: string, options?: UseQueryOptions<UserProfileSchema>) =>
  useQuery<UserProfileSchema>([ServerStateKeysEnum.USER_PROFILE, userId], () => getUserProfileFn(userId), options);

export const useAuthUserQuery = (options?: UseQueryOptions<IAuthMeResponse>) =>
  useQuery<IAuthMeResponse>([ServerStateKeysEnum.AUTH_USER], authMe, options);
