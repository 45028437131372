import { useMutation } from '@tanstack/react-query';
import { downloadApplicationPdfFn } from 'api/applicationsApi';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import React, { useMemo } from 'react';
import { FaDownload, FaEdit } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { Tooltip } from 'react-tooltip';
import { IApplication, IApplicationStatus } from 'types/api/applications/applications';
import { userProfileSchema, UserProfileSchema } from 'types/api/applications/userProfile';
import sendNotification from 'utils/notifications';

interface IViewModeApplicationDetailTitle {
  application: IApplication;
  userProfile?: UserProfileSchema;
  openEditMode: () => void;
}

const ViewModeApplicationDetailTitle: React.FC<IViewModeApplicationDetailTitle> = ({
  application,
  userProfile,
  openEditMode,
}) => {
  const isPdfDownloadEnabled = useMemo(() => {
    return true;
    // try {
    //   userProfileSchema.parse(userProfile);
    //   return true;
    // } catch (e: unknown) {
    //   console.log(e);
    //   return false;
    // }
  }, [userProfile]);

  const onDownloadApplicationPdfSuccess = (data: any) => {
    const file = new Blob([data], { type: 'application/pdf' });
    let pdfName = '';
    if (application.registry?.firstName && application.registry?.lastName)
      pdfName = `${application.registry.lastName.toLowerCase()}_${application.registry.firstName.toLowerCase()}.pdf`;
    else pdfName = `${application._id}.pdf`;

    fileDownload(file, pdfName);
  };

  const onDownloadApplicationPdfError = () =>
    sendNotification('Impossibile scaricare il dettaglio della candidatura (PDF)', '', 'error', true, 5000);
  const { mutate: downloadApplicationPdf, isLoading } = useMutation(downloadApplicationPdfFn, {
    onError: onDownloadApplicationPdfError,
    onSuccess: onDownloadApplicationPdfSuccess,
  });

  const onDownloadClick = () => downloadApplicationPdf({ id: application._id });

  return (
    <div className="w-full flex flex-row items-center justify-between">
      <div className="flex flex-col items-start">
        Dettagli candidatura
        <p className="font-medium text-sm underline text-white">
          Ultimo aggiornamento:
          {dayjs(application.updatedAt).fromNow()}
        </p>
      </div>
      <div className="flex flex-row items-center justify-center">
        <ApplicationStatusButton application={application} openEditMode={openEditMode} />
        <button
          disabled={!isPdfDownloadEnabled}
          onClick={onDownloadClick}
          className="flex flex-row gap-2 items-center bluemazza-button text-md font-medium rounded p-2 ml-2"
        >
          {isLoading ? <BeatLoader color="#0064a9" size={5} /> : <FaDownload />}
        </button>
      </div>
    </div>
  );
};

interface IApplicationStatusButton {
  application: IApplication;
  openEditMode: () => void;
}
const ApplicationStatusButton: React.FC<IApplicationStatusButton> = ({ application, openEditMode }) => {
  const getButtonStyle = () => {
    switch (application.status) {
      case IApplicationStatus.APPLICATION_CREATED:
        return 'bg-gray-500 font-thin';
      case IApplicationStatus.APPLICATION_COMPLETE:
        return 'bg-green-800 font-thin';
      case IApplicationStatus.APPLICATION_ACCEPTED:
        return 'bg-green-700  font-extralight';
      case IApplicationStatus.APPLICATION_REJECTED:
        return 'bg-red-700 font-extralight';
      case IApplicationStatus.PRESENT:
        return 'bg-green-600 font-normal';
      case IApplicationStatus.WITHDRAWN:
        return 'bg-red-600 font-normal';
      case IApplicationStatus.WINNER:
        return 'bg-green-500 font-medium';
      case IApplicationStatus.LOSER:
        return 'bg-red-500 font-medium';
      default:
        return '';
    }
  };

  return (
    <div
      data-tooltip-content="Modifica stato candidatura"
      id="editApplication"
      onClick={openEditMode}
      className={`cursor-pointer flex flex-row items-center p-2 rounded text-md ${getButtonStyle()}`}
    >
      <FaEdit /> <p className="pl-2">{application.status}</p>{' '}
      <Tooltip place="top" className="text-white bg-bluemazza" anchorId="editApplication" />
    </div>
  );
};

export default ViewModeApplicationDetailTitle;
