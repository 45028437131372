import axios from "axios"
import { IRefreshTokenResponse } from "types/api/auth"
import { getUserInCookie, setUserInCookie } from "utils/cookies"

const BASE_URL = "/api"

const baseApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
})

baseApi.defaults.headers.common["Content-Type"] = "application/json"

export async function refreshTokenFn(refreshToken: string): Promise<IRefreshTokenResponse> {
  const response = await baseApi.post("/auth/refreshToken", null, {
    headers: {
      "x-refresh": refreshToken,
    },
  })
  return response.data
}

baseApi.interceptors.request.use(async (req) => {
  // console.log("req interceptor ran")
  if (!req.headers) req.headers = {}
  if (!req.headers.Authorization) {
    // Already setted? Or avoid retry
    const userSessionInCookie = getUserInCookie()
    console.log(userSessionInCookie)
    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${userSessionInCookie?.accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    }
  }
  return req
})

baseApi.interceptors.response.use(
  async (res) => res,
  async (error) => {
    const prevRequest = error.config
    if (error.response?.status === 403 && !prevRequest.sent) {
      prevRequest.sent = true
      const userSessionInCookie = getUserInCookie()
      if (userSessionInCookie) {
        const { accessToken } = await refreshTokenFn(userSessionInCookie.refreshToken)
        setUserInCookie({ ...userSessionInCookie, accessToken })
        prevRequest.headers.Authorization = `Bearer ${accessToken}`
      }

      return axios(prevRequest)
    }
    return Promise.reject(error)
  }
)

export default baseApi
