import { useUser } from 'context/userContext';
import React, { useCallback } from 'react';
import { IconType } from 'react-icons';
import { FaClock, FaEnvelopeOpenText, FaHome, FaSignOutAlt, FaUserAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tooltip } from 'react-tooltip';
import { IAuthMeResponse } from 'types/api/auth';

type RouteProps = {
  name: string;
  url: string;
  icon: IconType;
  superAdmin: boolean;
};
const routes: RouteProps[] = [
  {
    name: 'Utenti',
    url: '/users',
    icon: FaUserAlt,
    superAdmin: true,
  },
  {
    name: 'Bandi',
    url: '/residences',
    icon: FaHome,
    superAdmin: false,
  },
  {
    name: 'Appelli',
    url: '/appeals',
    icon: FaClock,
    superAdmin: false,
  },
  {
    name: 'Candidature',
    url: '/applications',
    icon: FaEnvelopeOpenText,
    superAdmin: false,
  },
];

export default function Navbar() {
  const { state, dispatch } = useUser();
  const { authUser } = state;

  const onLogout = useCallback(() => dispatch({ type: 'LOGOUT', payload: null }), [dispatch]);

  return (
    <>
      {authUser && (
        <>
          <div className="hidden md:block z-10 " style={{ position: 'sticky', top: '5vh' }}>
            <SideBar routes={routes} user={authUser} onLogout={onLogout} />
          </div>
          <div className="block md:hidden fixed bottom-0 left-0 w-full z-10 ">
            <MobileNavBar routes={routes} user={authUser} />
          </div>
        </>
      )}
    </>
  );
}

interface IRouteComponent {
  route: RouteProps;
  isActive: boolean;
  user: IAuthMeResponse;
}
const RouteComponent: React.FC<IRouteComponent> = ({ route, isActive, user }) => {
  const navigate = useNavigate();
  const navigateToUrl = useCallback(() => navigate(route.url), [navigate, route]);
  const hasPermission = !route.superAdmin || user.adminPermissions === '*';

  return (
    <>
      {hasPermission && (
        <>
          <div
            data-tooltip-content={route.name}
            id={`route.${route.name}`}
            onClick={navigateToUrl}
            className={`p-4 transition-colors ease-in-out duration-300 text-lg cursor-pointer rounded ${
              isActive
                ? 'text-white bg-bluemazza shadow'
                : 'text-bluemazza  bg-white hover:text-white hover:bg-bluemazza'
            }`}
          >
            <route.icon />
          </div>
          <Tooltip place="right" className="text-white bg-bluemazza" anchorId={`route.${route.name}`} />
        </>
      )}
    </>
  );
};

interface ISideBar {
  routes: RouteProps[];
  onLogout: () => void;
  user: IAuthMeResponse;
}

const SideBar: React.FC<ISideBar> = ({ routes, onLogout, user }) => {
  const location = useLocation();
  return (
    <div
      className="flex flex-col p-4 bg-white rounded shadow items-center border justify-between gap-4"
      style={{ minHeight: '90vh' }}
    >
      <div className="w-12" data-tooltip-content="Backoffice Candidature - Collegio Mazza" id="logo">
        <img
          src="/images/pittogramma.png"
          className="transition ease duration-300 cursor-pointer object-contain hover:scale-110"
        />
      </div>
      <Tooltip place="right" className="text-white bg-bluemazza" anchorId={`logo`} />

      <div className="flex flex-col items-center justify-center gap-4">
        {routes.map((route) => (
          <RouteComponent key={route.url} route={route} isActive={location.pathname === route.url} user={user} />
        ))}
      </div>
      <button
        onClick={onLogout}
        className="bluemazza-button"
        data-tooltip-content={`${user?.email} - Logout`}
        id="logoutButton"
      >
        <FaSignOutAlt />
      </button>
      <Tooltip place="right" className="text-white bg-bluemazza" anchorId={`logoutButton`} />
    </div>
  );
};

interface IMobileNavBar {
  routes: RouteProps[];
  user: IAuthMeResponse;
}

const MobileNavBar: React.FC<IMobileNavBar> = ({ routes, user }) => {
  const location = useLocation();
  return (
    <div className="flex flex-row items-center justify-between p-4 bg-white rounded shadow border">
      {routes.map((route) => (
        <RouteComponent key={route.url} route={route} isActive={location.pathname === route.url} user={user} />
      ))}
    </div>
  );
};
