import React, { useCallback, useState } from 'react';

import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { ScaleLoader } from 'react-spinners';

import { useForm } from 'react-hook-form';

import { Alert } from '../../components/Shared/Alert/Alert';
import { useMutation } from '@tanstack/react-query';
import { loginFn } from 'api/authApi';
import ForgotPasswordDialog from 'components/Auth/Login/ForgotPasswordDialog/ForgotPasswordDialog';
import { setUserInCookie } from 'utils/cookies';
import { ILoginResponse } from 'types/api/auth';
import { useNavigate } from 'react-router-dom';
interface ILoginForm {
  email: string;
  password: string;
}
export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginForm>({ mode: 'all' });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isForgotAccountModalOpen, setIsForgotAccountModalOpen] = useState<boolean>(false);

  const onLoginSuccess = (data: ILoginResponse) => {
    setUserInCookie(data);
    navigate('/');
  };

  const {
    mutate: loginRequest,
    isLoading,
    isError,
  } = useMutation(loginFn, {
    onSuccess: onLoginSuccess,
  });

  const onSubmit = handleSubmit((data) => loginRequest({ email: data.email, password: data.password }));

  const toggleShowPassword = useCallback(() => setShowPassword((pass) => !pass), []);
  const openForgotAccountModal = useCallback(() => setIsForgotAccountModalOpen(true), []);
  const closeForgotAccountModal = useCallback(() => setIsForgotAccountModalOpen(false), []);

  const loginRequestError = isError ? 'Email o password non validi!' : undefined;

  return (
    <div className="w-full flex flex-col items-center justify-center py-8">
      <div className="w-8/12 md:w-2/12 py-8 ">
        <img
          src="/images/logo.png"
          className="transition ease duration-300 cursor-pointer object-contain hover:scale-110"
        />
      </div>
      <div className="animate-fade-in-right w-full md:w-4/12 shadow rounded bg-bluemazza px-4 py-8">
        <ForgotPasswordDialog isOpen={isForgotAccountModalOpen} onClose={closeForgotAccountModal} />
        <form onSubmit={onSubmit}>
          <h3 className="text-white text-xl font-bold text-center underline mb-4 ">Backoffice Candidature</h3>
          {isError && (
            <div className="mb-4">
              <Alert status="WARNING" title="Accesso fallito" description={loginRequestError} />
            </div>
          )}

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              {...register('email', {
                required: true,
                pattern: { value: /^\S+@\S+$/i, message: 'Email non valida' },
              })}
              className="auth-input"
              id="email"
              type="email"
              placeholder="Email"
            />
            {errors.email && <p className="input-error clear">{errors.email.message}</p>}
          </div>
          <div className="mb-4">
            <label className=" relative block text-white text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>

            <div className="relative block mb-3 ">
              <input
                {...register('password', {
                  required: true,
                  minLength: {
                    value: 6,
                    message: 'La password è minimo di 6 caratteri',
                  },
                })}
                className="auth-input"
                id="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
              />
              <button
                type="button"
                onClick={toggleShowPassword}
                className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
              >
                {showPassword ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
              </button>
            </div>
            {errors.password && <p className="input-error clear">{errors.password.message}</p>}
          </div>
          <div className="flex items-center justify-between w-full">
            <a
              className="underline underline-offset-4 cursor-pointer transition-colors ease duration-300 inline-block align-baseline font-medium text-sm text-white hover:text-orange-500"
              onClick={openForgotAccountModal}
            >
              Password dimenticata?
            </a>
            <button disabled={isLoading || !isValid} className="bluemazza-button" type="submit">
              {isLoading ? <ScaleLoader color="white" /> : 'Accedi'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
