import {
  IApplication,
  IDeleteApplication,
  IDownloadApplicationAttachment,
  IDownloadApplicationPdf,
  IDownloadApplicationsCsv,
  IUpdateApplication,
} from "types/api/applications/applications"
import baseApi from "./baseApi"

export async function getApplicationsFn(): Promise<IApplication[]> {
  const response = await baseApi.get("/backoffice/applications")
  return response.data
}

export async function updateApplicationFn(updateApplicationBody: IUpdateApplication): Promise<IApplication> {
  const response = await baseApi.put("/backoffice/applications/application", updateApplicationBody)
  return response.data
}

export async function deleteApplicationFn({ id }: IDeleteApplication) {
  const response = await baseApi.delete(`/backoffice/applications/${id}`)
  return response.data
}

export async function downloadApplicationAttachmentFn({ id, uploadKey }: IDownloadApplicationAttachment) {
  const response = await baseApi.get(`/backoffice/applications/${id}/uploads/${uploadKey}`, {
    responseType: "blob",
  })
  return response.data
}

export async function downloadApplicationPdfFn({ id }: IDownloadApplicationPdf) {
  const response = await baseApi.get(`/backoffice/applications/${id}/pdf`, {
    responseType: "blob",
  })
  return response.data
}

export async function downloadApplicationsCsvFn({ id, xlsx }: IDownloadApplicationsCsv) {
  const extension = xlsx ? "xlsx" : "csv"
  const response = await baseApi.post(
    `/backoffice/applications/${extension}`,
    { applications: id },
    {
      responseType: "blob",
    }
  )
  return response.data
}
