import { useMutation } from '@tanstack/react-query';
import { resetPasswordFn } from 'api/authApi';
import { Alert } from 'components/Shared/Alert/Alert';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { ROUTES } from 'appRoutes';

interface IResetPasswordForm {
  password: string;
  passwordConfirmation: string;
}
const ResetAccountPassword: React.FC = () => {
  const { userId, resetPasswordCode } = useParams();
  const navigate = useNavigate();

  const { mutate: resetPasswordRequest, isLoading, isError, isSuccess } = useMutation(resetPasswordFn);

  const handleResetPassword = useCallback(
    ({ password, passwordConfirmation }: IResetPasswordForm) => {
      if (userId && resetPasswordCode)
        resetPasswordRequest({
          id: userId,
          passwordResetCode: resetPasswordCode,
          password,
          passwordConfirmation,
        });
    },
    [userId, resetPasswordCode, resetPasswordRequest]
  );

  // Page guard: userId and verificationCode mandatory
  useEffect(() => {
    if (!(userId && resetPasswordCode)) navigate(ROUTES.AUTH_LOGIN);
  }, [userId, resetPasswordCode, navigate]);

  if (!(userId && resetPasswordCode)) return <></>;

  return (
    <div className="w-full flex flex-col items-center justify-center py-8">
      <div className="w-8/12 md:w-2/12 py-8 ">
        <img
          src="/images/logo.png"
          className="transition ease duration-300 cursor-pointer object-contain hover:scale-110"
        />
      </div>
      <div className="animate-fade-in-right w-full md:w-4/12 shadow rounded bg-bluemazza px-4 py-8">
        <h3 className="text-white text-xl font-bold text-center underline mb-4 ">Attiva il tuo account</h3>
        {isError && (
          <div className="mb-4">
            <Alert
              status="WARNING"
              title="Reset password fallito"
              description="Impossibile resettare la tua password.Il link cliccato potrebbe essere scaduto o già utilizzato."
            />
          </div>
        )}
        <ResetAccountPasswordContent
          isLoading={isLoading}
          isSuccess={isSuccess}
          handleResetPassword={handleResetPassword}
        />
      </div>
    </div>
  );
};

interface IResetAccountPasswordContent {
  isSuccess: boolean;
  isLoading: boolean;
  handleResetPassword: (data: IResetPasswordForm) => void;
}
const ResetAccountPasswordContent: React.FC<IResetAccountPasswordContent> = ({
  isSuccess,
  isLoading,
  handleResetPassword,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IResetPasswordForm>({
    mode: 'all',
  });

  const watchPassword: string = watch('password', '');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

  const toggleShowPassword = useCallback(() => setShowPassword((pass) => !pass), []);
  const toggleShowPasswordConfirmation = useCallback(() => setShowPasswordConfirmation((pass) => !pass), []);

  const onSubmit = handleSubmit((data) => {
    const { password, passwordConfirmation } = data;
    handleResetPassword({ password, passwordConfirmation });
  });

  if (isSuccess)
    return <Alert status="SUCCESS" title="Password aggiornata" description="Password modificata con successo." />;

  return (
    <form onSubmit={onSubmit}>
      <div className="w-full mb-4">
        <label className="block text-white text-sm font-bold mb-2" htmlFor="password">
          Password
        </label>
        <div className="relative block mb-3 ">
          <input
            {...register('password', {
              required: true,
              minLength: {
                value: 6,
                message: 'La lunghezza minima è 6 caratteri',
              },
            })}
            className="auth-input"
            id="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
          />
          <button
            type="button"
            onClick={toggleShowPassword}
            className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
          >
            {showPassword ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
          </button>
        </div>
        {errors.password && <p className="input-error">{errors.password.message}</p>}
      </div>
      <div className="w-full mb-4 ">
        <label className="block text-white text-sm font-bold mb-2" htmlFor="passwordConfirmation">
          Conferma password
        </label>
        <div className="relative block mb-3 ">
          <input
            {...register('passwordConfirmation', {
              required: true,
              validate: (value) => value === watchPassword || 'Le password non combaciano',
            })}
            className="auth-input"
            id="passwordConfirmation"
            type={showPasswordConfirmation ? 'text' : 'password'}
            placeholder="Conferma Password"
          />
          <button
            type="button"
            onClick={toggleShowPasswordConfirmation}
            className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
          >
            {showPasswordConfirmation ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
          </button>
        </div>
        {errors.passwordConfirmation && <p className="input-error">{errors.passwordConfirmation.message}</p>}
      </div>
      <div className="flex items-center justify-center w-full">
        <button disabled={isLoading || !isValid} className="bluemazza-button" type="submit">
          {isLoading ? <ScaleLoader color="white" /> : 'Aggiorna password'}
        </button>
      </div>
    </form>
  );
};

export default ResetAccountPassword;
