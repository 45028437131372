import React from "react"
import DataTable, { TableProps, createTheme } from "react-data-table-component"

const customStyles = {
  header: {
    style: {
      // minHeight: '56px',
      justifyContent: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  table: {
    style: {
      marginTop: "32px",
    },
  },
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
}
// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme(
  "dark",
  {
    background: {
      default: "transparent",
    },
    context: {
      background: "transparent",
      text: "#FFFFFF",
    },
    divider: {
      default: "#166886",
    },
  },
  "dark"
)

interface IExpandedComponent {
  data: any
}
const ExpandedComponent: React.FC<IExpandedComponent> = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>

function DataTableBase<T>(props: TableProps<T>): JSX.Element {
  return (
    <DataTable
      theme="light"
      pagination
      responsive
      expandableRowsComponent={ExpandedComponent}
      customStyles={customStyles}
      paginationComponentOptions={{
        rowsPerPageText: "Righe per pagina:",
        rangeSeparatorText: "di",
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: "Tutti",
      }}
      contextMessage={{
        singular: "elemento",
        plural: "elementi",
        message: "selezionati",
      }}
      {...props}
    />
  )
}

export default DataTableBase
