import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateApplicationFn } from "api/applicationsApi"
import DeleteApplicationDialog from "components/Applications/DeleteApplicationDialog/DeleteApplicationDialog"
import { AlertInline } from "components/Shared/Alert/Alert"
import { ServerStateKeysEnum } from "features/queries"
import React, { useCallback, useState } from "react"
import { IAppeal } from "types/api/appeals"
import { IApplication, IApplicationFinalStatus } from "types/api/applications/applications"
import { IResidence } from "types/api/residences"
import sendNotification from "utils/notifications"
import EditApplicationForm from "../EditApplicationForm/EditApplicationForm"

interface IEditModeApplicationDetailContent {
  application: IApplication
  applicationAppeal?: IAppeal
  applicationResidence?: IResidence
  onClose: () => void
}

const EditModeApplicationDetailContent: React.FC<IEditModeApplicationDetailContent> = ({
  application,
  applicationAppeal,
  applicationResidence,
  onClose,
}) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false)

  const openDeleteConfirmation = useCallback(() => setIsDeleteConfirmationOpen(true), [])
  const closeDeleteConfirmation = useCallback(() => setIsDeleteConfirmationOpen(false), [])

  const isApplicationStatusFinal = Object.values(IApplicationFinalStatus).includes(application.status as any)

  const queryClient = useQueryClient()

  const onUpdateSuccess = async (data: IApplication) => {
    await queryClient.cancelQueries([ServerStateKeysEnum.APPLICATIONS])
    const previousValue = queryClient.getQueryData<IApplication[]>([ServerStateKeysEnum.APPLICATIONS]) || []

    const currentApplicationIndex = previousValue.findIndex((app) => app._id === application._id)
    if (currentApplicationIndex !== -1) {
      const updatedArray = [...previousValue]
      updatedArray[currentApplicationIndex] = data
      queryClient.setQueryData([ServerStateKeysEnum.APPLICATIONS], updatedArray)
    }
    sendNotification("Candidatura aggiornata", "", "success", true, 5000)
  }

  const onUpdateError = () => sendNotification("Impossibile aggiornare la candidatura", "", "error", true, 5000)
  const { mutate, isLoading } = useMutation(updateApplicationFn, { onSuccess: onUpdateSuccess, onError: onUpdateError })

  const editApplication = (appeal_id: string, status: IApplicationFinalStatus) =>
    mutate({ id: application._id, data: { appeal_id, status } })

  const closeApplicationDetail = () => {
    closeDeleteConfirmation()
    onClose()
  }

  return (
    <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
      {isApplicationStatusFinal && (
        <AlertInline
          title="Attenzione!"
          description={`La candidatura è già stata finalizzata (${application.status})`}
          status="WARNING"
        />
      )}
      <DeleteApplicationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmation}
        application={application}
        closeApplicationDetail={closeApplicationDetail}
      />
      <EditApplicationForm
        application={application}
        applicationAppeal={applicationAppeal}
        applicationResidence={applicationResidence}
        openDeleteDialog={openDeleteConfirmation}
        editApplication={editApplication}
        isLoading={isLoading}
      />
    </div>
  )
}

// const [editApplicationRequestStatus, setEditApplicationRequestStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED)

// const editApplication = async (
//   application: ApplicationModel,
//   appeal: AppealModel,
//   status: ApplicationFinalStatusType
// ) => {
//   try {
//     setEditApplicationRequestStatus(FetchStatus.LOADING)
//     const updatedApplication = await axios.put<ApplicationModel>(
//       `/api/backoffice/residences/appeals/applications/${application._id}`,
//       {
//         appeal_id: appeal._id,
//         status,
//       }
//     )
//     setEditApplicationRequestStatus(FetchStatus.SUCCESS)
//     const existingApplicationIndex = applications.findIndex((appl) => appl._id === application._id)
//     if (existingApplicationIndex !== -1) {
//       // update the registry with the old one because the response does not contain it
//       updatedApplication.data.registry = applications[existingApplicationIndex].registry
//       const shadowApplications = [...applications] // important to create a copy, otherwise you'll modify state outside of setState call
//       shadowApplications[existingApplicationIndex] = updatedApplication.data
//       setApplications(shadowApplications)
//       // if (selectedApplication?._id === application._id)
//       setSelectedApplication(updatedApplication.data)

//       sendNotification("Candidatura aggiornata", "", "success", true, 5000)
//     } else
//       sendNotification(
//         "Candidatura aggiornata, ricarica la pagina per vedere le modifiche",
//         "",
//         "warning",
//         true,
//         5000
//       )

//     // update value in state
//   } catch (e: any) {
//     sendNotification("Impossibile aggiornare la candidatura", "", "error", true, 5000)
//     setEditApplicationRequestStatus(FetchStatus.ERROR)
//   }
// }
export default EditModeApplicationDetailContent
