import { useMutation } from "@tanstack/react-query"
import { forgotPasswordFn } from "api/authApi"
import { Alert } from "components/Shared/Alert/Alert"
import Modal from "components/Shared/Modal/Modal"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { ScaleLoader } from "react-spinners"
import { ROUTES } from "appRoutes"

interface IForgotPasswordDialog {
  isOpen: boolean
  onClose: () => void
}
const ForgotPasswordDialog: React.FC<IForgotPasswordDialog> = ({ isOpen, onClose }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({ mode: "all" })

  const { mutate: forgotPasswordRequest, isLoading, isSuccess } = useMutation(forgotPasswordFn)

  const onSubmit = handleSubmit((data) => forgotPasswordRequest(data.email))

  const onSuccessClose = useCallback(() => {
    onClose()
    navigate(ROUTES.AUTH_LOGIN)
  }, [onClose, navigate])

  const modalTitle = "Recuper la tua password"
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={modalTitle}>
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        {isSuccess ? (
          <div className="flex flex-col items-center gap-4">
            <Alert
              status="SUCCESS"
              title="Controlla la tua email"
              description="Se l'email inserita è associata ad un account esistente, riceverai una mail contenente le istruzioni per recuperare il tuo account"
            />
            <button
              onClick={onSuccessClose}
              className="transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Accedi
            </button>
          </div>
        ) : (
          <form className="flex flex-col items-start gap-4 w-full" onSubmit={onSubmit}>
            <p className="text-sm text-gray-500">Inserisci la tua email per recuperare l&#39;account</p>
            <div className="w-full">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              {/* onChange={(e) => setEmail(e.target.value)} */}
              <input
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Email non valida",
                  },
                })}
                className="form-input"
                id="email"
                type="email"
                placeholder="Email"
              />
              {errors.email && <p className="input-error">{errors.email.message}</p>}
            </div>
            <button
              disabled={isLoading || !isValid}
              className="self-center disabled:opacity-50 disabled:bg-blue-500 transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {isLoading ? <ScaleLoader color="white" /> : "Recupera la password"}
            </button>
          </form>
        )}
      </div>
    </Modal>
  )
}

export default ForgotPasswordDialog
