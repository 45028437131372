import Select from 'components/Shared/Select/Select';
import dayjs from 'dayjs';
import { useAppealsQuery, useResidencesQuery } from 'features/queries';
import React, { useEffect, useMemo, useState } from 'react';
import { FaSave, FaTrashAlt } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { Tooltip } from 'react-tooltip';
import { IAppeal } from 'types/api/appeals';
import { IApplication, IApplicationFinalStatus, IApplicationStatus } from 'types/api/applications/applications';
import { IResidence } from 'types/api/residences';

interface IEditApplicationForm {
  application: IApplication;
  applicationResidence?: IResidence;
  applicationAppeal?: IAppeal;
  openDeleteDialog: () => void;
  isLoading: boolean;
  editApplication: (appeal_id: string, status: IApplicationFinalStatus) => void;
}
const EditApplicationForm: React.FC<IEditApplicationForm> = ({
  application,
  applicationResidence,
  applicationAppeal,
  openDeleteDialog,
  isLoading,
  editApplication,
}) => {
  const { data: appeals = [] } = useAppealsQuery();
  const { data: residences = [] } = useResidencesQuery();

  const [selectedResidence, setSelectedResidence] = useState<IResidence | undefined>(applicationResidence);
  const [selectedAppeal, setSelectedAppeal] = useState<IAppeal | undefined>(applicationAppeal);
  const [selectedStatus, setSelectedStatus] = useState<IApplicationStatus>(application.status);

  const onResidenceChange = (value: string) => {
    const residenceExist = residences.find((residence) => residence._id === value);
    if (residenceExist) setSelectedResidence(residenceExist);
  };

  const onAppealChange = (value: string) => {
    const appealExist = appeals.find((appeal) => appeal._id === value);
    if (appealExist) setSelectedAppeal(appealExist);
  };

  const onStatusChange = (value: string) => {
    setSelectedStatus(value as IApplicationStatus);
  };

  const residenceAppeals = useMemo(() => {
    if (selectedResidence)
      return appeals.filter(
        (appeal) => dayjs(appeal.date).year() === dayjs().year() && appeal.residence_id === selectedResidence._id
      );
    return [];
  }, [selectedResidence, appeals]);

  //update selectedAppeal whenever residence changes
  useEffect(() => {
    if (!residenceAppeals.length) return setSelectedAppeal(undefined);

    const applicationAppeal = residenceAppeals.find((appeal) => appeal._id === application.appeal_id);
    if (applicationAppeal) setSelectedAppeal(applicationAppeal);
    else setSelectedAppeal(residenceAppeals[0]);
  }, [residenceAppeals, application]);

  const residencesOptions = useMemo(() => residences.map((res) => ({ value: res._id, label: res.name })), [residences]);
  const appealsOptions = useMemo(
    () =>
      residenceAppeals.map((appeal) => {
        const label = `${dayjs(appeal.date).format('DD/MM/YYYY')} - Candidature${' '} ${
          dayjs(appeal.expiryDate).isAfter(dayjs()) ? 'in scadenza' : 'scadute'
        }  ${dayjs(appeal.expiryDate).fromNow()}`;
        return { value: appeal._id, label };
      }),
    [residenceAppeals]
  );
  const statusesOptions = useMemo(
    () =>
      Object.keys(IApplicationStatus).map((status) => {
        const labelValue = IApplicationStatus[status as keyof typeof IApplicationStatus];
        return { label: labelValue, value: labelValue };
      }),
    []
  );

  const statusesDisabledOptions = useMemo(
    () =>
      Object.keys(IApplicationStatus)
        .filter((status) => {
          const isDisabled = !Object.values(IApplicationFinalStatus).includes(
            IApplicationFinalStatus[status as keyof typeof IApplicationFinalStatus]
          );
          return isDisabled;
        })
        .map((status) => {
          const labelValue = IApplicationStatus[status as keyof typeof IApplicationStatus];
          return { label: labelValue, value: labelValue };
        }),
    []
  );

  const residencesTextHelper = () => {
    if (selectedResidence && applicationResidence && selectedResidence._id !== applicationResidence._id)
      return (
        <p className="text-orange-500 text-sm mt-1 underline underline-offset-4">
          Il bando differisce da quello salvato ({applicationResidence.name})
        </p>
      );
    return (
      <p className="text-green-500 text-sm mt-1 underline underline-offset-4">Il bando combacia con quella salvata</p>
    );
  };

  const appealsTextHelper = () => {
    if (selectedAppeal && applicationAppeal && selectedAppeal._id !== applicationAppeal._id)
      return (
        <p className="text-orange-500 text-sm mt-1 underline underline-offset-4">
          L&#8203;appello differisce da quello attualmente salvato ({dayjs(selectedAppeal.date).format('DD/MM/YYYY')}){' '}
        </p>
      );
    return (
      <p className="text-green-500 text-sm mt-1 underline underline-offset-4">
        L&#8203;appello combacia con quello salvato
      </p>
    );
  };

  const statusTextHelper = () => {
    const isFinalState = Object.values(IApplicationFinalStatus).includes(application.status as any);
    const isComplete = application.status === IApplicationStatus.APPLICATION_COMPLETE;
    if (isFinalState)
      return (
        <p className="text-orange-500 text-sm mt-1 underline underline-offset-4">
          Attenzione! Lo stato di questa candidatura è già stato modificato ({application.status})
        </p>
      );
    if (!isComplete)
      <p className="text-orange-500 text-sm mt-1 underline underline-offset-4">
        Attenzione! La candidatura non è ancora completa ({application.status})
      </p>;
    return (
      <p className="text-green-500 text-sm mt-1 underline underline-offset-4">
        La candidatura è completa, puoi procedere a cambiarne lo stato
      </p>
    );
  };

  const isSaveDisabled =
    !selectedAppeal || !selectedStatus || !Object.values(IApplicationFinalStatus).includes(selectedStatus as any);

  const onSubmit = () => {
    if (!isSaveDisabled) editApplication(selectedAppeal._id, selectedStatus as any);
  };
  return (
    <>
      <div className="w-full my-4">
        <Select
          selectId="residence"
          value={selectedResidence?._id}
          label="Bando"
          onChange={onResidenceChange}
          options={residencesOptions}
        />
        {residencesTextHelper()}
      </div>
      <div className="w-full mb-4">
        <Select
          disabled={!selectedResidence}
          selectId="appeal"
          value={selectedAppeal?._id}
          label="Data"
          onChange={onAppealChange}
          options={appealsOptions}
          noOptions={<p>Nessuna data disponibile</p>}
        />
        {appealsTextHelper()}
      </div>
      <div className="w-full mb-4">
        <Select
          label="Stato"
          selectId="status"
          value={selectedStatus}
          onChange={onStatusChange}
          options={statusesOptions}
          disabledOptions={statusesDisabledOptions}
        />
        {statusTextHelper()}
      </div>
      <div className="w-full flex flex-row items-center justify-between">
        <Tooltip place="top" className="text-white bg-bluemazza" anchorId="deleteApplication" />
        <button
          onClick={openDeleteDialog}
          data-tooltip-content="Attenzione! Anche i relativi allegati saranno rimossi"
          id="deleteApplication"
          className="red-button flex flex-row items-center justify-center"
        >
          <FaTrashAlt />
          <span className="ml-2">Elimina</span>
        </button>
        <div className="self-end flex flex-col items-end justify-center">
          <button
            disabled={isSaveDisabled}
            onClick={onSubmit}
            className="bluemazza-button flex flex-row items-center justify-center"
          >
            <FaSave />
            {isLoading ? <BeatLoader size={10} /> : <span className="ml-2">Aggiorna candidatura</span>}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditApplicationForm;
