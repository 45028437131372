import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createAppealFn } from "api/residencesApi"
import { CustomDatePickerInput } from "components/reactDatepicker"
import React, { Fragment, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { IAppeal } from "types/api/appeals"
import DatePicker from "react-datepicker"
import dayjs from "utils/dayjs"
import { ScaleLoader } from "react-spinners"
import { ServerStateKeysEnum, useAppealsQuery, useResidencesQuery } from "features/queries"
import Modal from "components/Shared/Modal/Modal"
import sendNotification from "utils/notifications"

interface INewAppealDialog {
  isOpen: boolean
  onClose: () => void
}

interface NewAppealForm {
  residence_id: string
  date: Date
  expiryDate: Date
  acceptCandidacyFromDate: Date
}
const NewAppealDialog: React.FC<INewAppealDialog> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient()

  const { data: appeals = [] } = useAppealsQuery()
  const { data: residences = [] } = useResidencesQuery()

  const onCreateAppealSuccess = async (data: IAppeal) => {
    await queryClient.cancelQueries([ServerStateKeysEnum.APPEALS])
    const previousValue = queryClient.getQueryData<IAppeal[]>([ServerStateKeysEnum.APPEALS]) || []

    queryClient.setQueryData([ServerStateKeysEnum.APPEALS], [...previousValue, data])
    sendNotification("Appello creato con successo", "", "success", true, 5000)
    onClose()
  }

  const onCreateAppealError = () => sendNotification("Impossibile creare l'appello", "", "success", true, 5000)

  const { mutate: createAppealMutation, isLoading } = useMutation(createAppealFn, {
    onSuccess: onCreateAppealSuccess,
    onError: onCreateAppealError,
  })

  const { register, control, watch, handleSubmit, setError, clearErrors, formState } = useForm<NewAppealForm>({
    mode: "all",
    defaultValues: {
      acceptCandidacyFromDate: dayjs().toDate(),
    },
  })

  const { errors, isValid } = formState

  const watchResidenceId: string = watch("residence_id")
  const watchDate: Date = watch("date")
  const watchExpiryDate: Date = watch("expiryDate")
  const watchAcceptCandidacyFromDate: Date = watch("acceptCandidacyFromDate")

  const createAppeal = (data: NewAppealForm) => {
    const { residence_id, date, expiryDate, acceptCandidacyFromDate } = data
    createAppealMutation({
      residence_id,
      date,
      expiryDate,
      acceptCandidacyFromDate,
    })
  }

  useEffect(() => {
    console.log(watchDate, watchExpiryDate, watchResidenceId)
    if (watchDate && watchExpiryDate) {
      if (watchExpiryDate > watchDate)
        setError("expiryDate", {
          message: "La data di scadenza non può essere dopo quella dell'appello",
        })
      else clearErrors("expiryDate")
    }

    if (watchAcceptCandidacyFromDate && watchDate) {
      if (watchAcceptCandidacyFromDate > watchDate)
        setError("acceptCandidacyFromDate", {
          message: "La data di inizio non può essere dopo quella dell'appello",
        })
      else clearErrors("acceptCandidacyFromDate")
    }

    if (watchDate) {
      console.log(watchDate)
      const appealAlreadyExist = appeals.find(
        (appeal) => appeal.residence_id === watchResidenceId && dayjs(watchDate).isSame(dayjs(appeal.date), "day")
      )
      if (appealAlreadyExist)
        setError("date", {
          message: "Esiste già un appello in questa data per questo bando",
        })
      else clearErrors("date")
    }
  }, [watchDate, watchExpiryDate, watchAcceptCandidacyFromDate, watchResidenceId, appeals, clearErrors, setError])

  const modalTitle = "Nuovo appello"
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={modalTitle}>
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        <form className="w-full flex flex-col justify-between items-start gap-2" onSubmit={handleSubmit(createAppeal)}>
          <div className="w-full">
            <label className="form-label" htmlFor="residence_id">
              Bando
            </label>

            <select {...register("residence_id", { required: true })} className="form-input" id="residence_id">
              {residences.map((residence, idx) => (
                <option key={idx} value={residence._id}>
                  {residence.name}
                </option>
              ))}
            </select>
            {errors.residence_id && <p className="input-error">{errors.residence_id.message}</p>}
          </div>
          <div className="w-full">
            <label className="form-label" htmlFor="date">
              Data
            </label>

            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  {/* <div className='hidden xl:block'> */}
                  <DatePicker
                    placeholderText="Seleziona la data"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: Date) => field.onChange(dayjs(date).startOf("day").toDate())}
                    selected={field.value}
                    customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                    showMonthDropdown
                    showYearDropdown
                    minDate={dayjs().toDate()}
                    dropdownMode="select"
                  />
                </>
              )}
            />
            {errors.date && <p className="input-error">{errors.date.message}</p>}
          </div>
          <div className="w-full">
            <label className="form-label" htmlFor="expiryDate">
              Scadenza
            </label>

            <Controller
              control={control}
              name="expiryDate"
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  {/* <div className='hidden xl:block'> */}
                  <DatePicker
                    placeholderText="Seleziona la data"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: Date) => {
                      console.log(dayjs(date).endOf("day").toDate())
                      field.onChange(dayjs(date).endOf("day").toDate())
                    }}
                    selected={field.value}
                    customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                    showMonthDropdown
                    showYearDropdown
                    // minDate={dayjs().subtract(40, 'year').toDate()}
                    minDate={dayjs().toDate()}
                    dropdownMode="select"
                  />
                  {/* </div>
                                                          <input className={`block xl:hidden form-input ${errors.expiryDate && 'error'}`} value={field.value && dayjs(field.value).format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').toDate() : undefined) }} id="expiryDate" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                </>
              )}
            />
            {errors.expiryDate && <p className="input-error">{errors.expiryDate.message}</p>}
          </div>
          <div className="w-full">
            <label className="form-label" htmlFor="acceptCandidacyFromDate">
              Data di partenza
            </label>

            <Controller
              control={control}
              name="acceptCandidacyFromDate"
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <DatePicker
                    placeholderText="Seleziona la data"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: Date) => field.onChange(dayjs(date).startOf("day").toDate())}
                    selected={field.value}
                    customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                    showMonthDropdown
                    showYearDropdown
                    minDate={dayjs().toDate()}
                    dropdownMode="select"
                  />
                  {/* </div>
                                                          <input className={`block xl:hidden form-input ${errors.acceptCandidacyFromDate && 'error'}`} value={field.value && dayjs(field.value).format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').toDate() : undefined) }} id="acceptCandidacyFromDate" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                </>
              )}
            />
            {errors.acceptCandidacyFromDate && <p className="input-error">{errors.acceptCandidacyFromDate.message}</p>}
          </div>
          <button
            disabled={isLoading || !isValid}
            className="disabled:opacity-50 disabled:bg-blue-500 self-center transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {isLoading ? <ScaleLoader color="white" /> : "Crea Appello"}
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default NewAppealDialog
