import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteAppealFn } from "api/residencesApi"
import Modal from "components/Shared/Modal/Modal"
import React from "react"
import { FaTrashAlt } from "react-icons/fa"
import { BeatLoader } from "react-spinners"
import { IAppeal } from "types/api/appeals"
import sendNotification from "utils/notifications"

interface IDeleteConfirmationDialog {
  isOpen: boolean
  onClose: () => void
  appeal: IAppeal
}
const DeleteConfirmationDialog: React.FC<IDeleteConfirmationDialog> = ({ isOpen, onClose, appeal }) => {
  const queryClient = useQueryClient()

  const onDeleteAppealSuccess = async () => {
    await queryClient.cancelQueries(["appeals"])
    const previousValue = queryClient.getQueryData<IAppeal[]>(["appeals"]) || []

    const currentAppealIndex = previousValue.findIndex((app) => app._id === appeal._id)
    if (currentAppealIndex !== -1) {
      const updatedArray = [...previousValue]
      updatedArray.splice(currentAppealIndex, 1)
      console.log(updatedArray)
      queryClient.setQueryData(["appeals"], updatedArray)
    }

    sendNotification("Appello rimosso con successo", "", "success", true, 5000)
    onClose()
  }

  const onDeleteAppealError = () => {
    sendNotification("Impossibile rimuovere l'appello", "", "error", true, 5000)
  }

  const { mutate: deleteAppealMutation, isLoading } = useMutation(deleteAppealFn, {
    onSuccess: onDeleteAppealSuccess,
    onError: onDeleteAppealError,
  })

  const onConfirm = () => deleteAppealMutation({ id: appeal._id })

  const modalTitle = "Conferma rimozione"
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={modalTitle} type="danger">
      <div className=" p-4 flex flex-col justify-between items-start gap-2 w-full">
        <p className="text-lg font-bold"> Sei sicuro di voler eliminare l&#39;appello selezionato? </p>
        {/* <p className="text-md font-medium"> Nel caso fossero presenti dei candidati, riceveranno una notifica via email e gli sarà richiesto di selezionare un altro appello. </p> */}
        <p className="text-md font-medium">
          {" "}
          Nel caso fossero presenti dei candidati, gli verrà chiesto di selezionare un altro appello (dovranno
          ricaricare la pagina){" "}
        </p>
        <div className="w-full flex flex-row items-center justify-between mt-4">
          <button onClick={onClose} className="bluemazza-button">
            Annulla
          </button>
          <button disabled={isLoading} onClick={onConfirm} className="red-button">
            <span className="flex flex-row items-center gap-2">
              {isLoading ? (
                <BeatLoader className="text-white" size={5} />
              ) : (
                <>
                  <FaTrashAlt />
                  <p>Elimina</p>
                </>
              )}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteConfirmationDialog
