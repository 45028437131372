import { useUser } from "context/userContext"
import React, { useCallback } from "react"

const Header: React.FC = () => {
  const { state, dispatch } = useUser()
  const { authUser } = state

  const onLogout = useCallback(() => dispatch({ type: "LOGOUT", payload: null }), [dispatch])
  return (
    <div className="my-4 w-full flex  flex-row justify-between items-center">
      <div className="w-6/12 md:w-2/12">
        <img
          alt="cm-logo"
          src="/images/logo.png"
          className="transition ease duration-300 cursor-pointer object-contain hover:scale-110"
        />
      </div>
      {authUser && (
        <div className="self-end flex flex-col items-end pt-4 md:pt-0">
          <button
            type="button"
            onClick={onLogout}
            className="cursor-pointer text-bluemazza text-md font-bold transition-colors ease duration-300 hover:text-orange-500"
          >
            Logout
          </button>
          <p className="text-lightbluemazza text-xl font-extrabold">{authUser.email}</p>
        </div>
      )}
    </div>
  )
}

export default Header
