import dayjs from 'dayjs';
import { useAppealsQuery } from 'features/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { IResidence } from 'types/api/residences';

const useResidencesColumns = () => {
  const [residencesColumns, setResidencesColumns] = useState<TableColumn<IResidence>[]>([]);

  const { data: appeals = [] } = useAppealsQuery();

  const getResidenceAppeals = useCallback(
    (residence: IResidence) =>
      appeals.filter(
        (appeal) =>
          appeal.residence_id === residence._id &&
          dayjs() < dayjs(appeal.expiryDate) &&
          dayjs() > dayjs(appeal.acceptCandidacyFromDate)
      ),
    [appeals]
  );

  useEffect(() => {
    const parsedResidencesColumns: TableColumn<IResidence>[] = [
      {
        name: 'Nome',
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: 'Email associate',
        selector: (row) => row.emails.length,
        sortable: true,
      },
      {
        name: 'Appelli attivi',
        selector: (row) => {
          const residenceAppeals = getResidenceAppeals(row);
          return residenceAppeals.length;
        },
        cell: (row) => {
          const residenceAppeals = getResidenceAppeals(row);
          return (
            <p className={`${residenceAppeals.length > 0 && 'text-green-500 text-bold'}`}>{residenceAppeals.length}</p>
          );
        },
        sortable: true,
      },
    ];
    setResidencesColumns(parsedResidencesColumns);
  }, [appeals, getResidenceAppeals]);

  return { residencesColumns };
};

export default useResidencesColumns;
