import dayjs from 'dayjs';
import { useAppealsQuery, useApplicationsQuery, useResidencesQuery, useUsersQuery } from 'features/queries';
import React, { useCallback, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { IApplication, IApplicationStatus } from 'types/api/applications/applications';

interface IuseApplicationsColumns {
  onRowClick: (application: IApplication) => void;
}
const useApplicationsColumns = ({ onRowClick }: IuseApplicationsColumns) => {
  const [applicationsColumns, setApplicationsColumns] = useState<TableColumn<IApplication>[]>([]);

  const { data: appeals = [] } = useAppealsQuery();
  const { data: users = [] } = useUsersQuery();
  const { data: applications = [] } = useApplicationsQuery();
  const { data: residences = [] } = useResidencesQuery();

  const getResidenceStyle = useCallback(
    ({ _id }: any) => {
      const styles = [
        'bg-rose-500 text-white',
        'bg-fuchsia-500 text-white',
        'bg-violet-500 text-white',
        'bg-blue-500 text-white',
        'bg-cyan-500 text-white',
        'bg-indigo-500 text-white',
        'bg-purple-500 text-white',
        'bg-teal-500 text-white',
        'bg-emerald-500 text-white',
        'bg-lime-500 text-black',
        'bg-amber-500 text-black',
        'bg-red-500 text-white',
        'bg-green-500 text-white',
      ];
      const index = residences.findIndex((item) => item._id === _id);
      if (index === -1) {
        return 'bg-slate-500 text-white';
      }
      return styles[index];
    },
    [residences]
  );
  const getStatusStyle = useCallback(
    (application: IApplication) => {
      switch (application.status) {
        case IApplicationStatus.APPLICATION_CREATED:
          return 'text-gray-500 font-light';
        case IApplicationStatus.APPLICATION_COMPLETE:
          return 'text-green-500 font-light';
        case IApplicationStatus.APPLICATION_ACCEPTED:
          return 'text-green-500 underline font-normal';
        case IApplicationStatus.APPLICATION_REJECTED:
          return 'text-red-500 underline font-normal';
        case IApplicationStatus.PRESENT:
          return 'bg-green-700 font-medium rounded-full text-white px-2 py-1';
        case IApplicationStatus.WITHDRAWN:
          return 'bg-red-700 font-medium rounded-full text-white px-2 py-1';
        case IApplicationStatus.WINNER:
          return 'bg-green-500 font-bold rounded-full text-white px-2 py-1';
        case IApplicationStatus.LOSER:
          return 'bg-red-500 font-bold rounded-full text-white px-2 py-1';
        default:
          return '';
      }
    },

    []
  );

  useEffect(() => {
    if (applications.length) {
      const parsedApplicationsColumns: TableColumn<IApplication>[] = [
        {
          name: 'Utente',
          selector: (row) => {
            const relatedUser = users.find((user) => user._id === row.user_id);
            const selectorText = row.registry
              ? `${row.registry.lastName} ${row.registry.firstName}`
              : relatedUser?.email || 'Utente non trovato';
            return selectorText;
          },
          cell: (row) => {
            const relatedUser = users.find((user) => user._id === row.user_id);
            const cellText = row.registry
              ? `${row.registry.lastName} ${row.registry.firstName}`
              : relatedUser?.email || 'Utente non trovato';
            const cellStyle = !relatedUser?.email
              ? 'text-red-500 underline'
              : !row.registry && 'text-orange-500 underline';

            return (
              <p onClick={() => onRowClick(row)} className={`${cellStyle}`}>
                {cellText}
              </p>
            );
          },
          sortable: true,
        },
        {
          name: 'Stato',
          selector: (row) => row.status,
          cell: (row) => {
            return (
              <p onClick={() => onRowClick(row)} className={getStatusStyle(row)}>
                {row.status}
              </p>
            );
          },
          sortable: true,
        },
        {
          name: 'Bando',
          selector: (row) => {
            const relatedAppeal = appeals.find((appeal) => appeal._id === row.appeal_id);
            if (relatedAppeal)
              return (
                residences.find((residence) => residence._id === relatedAppeal.residence_id)?.name ||
                'Bando non trovato'
              );
            return 'Appello non trovato';
          },
          cell: (row) => {
            const relatedAppeal = appeals.find((appeal) => appeal._id === row.appeal_id);
            if (relatedAppeal) {
              const relatedResidence = residences.find((residence) => residence._id === relatedAppeal.residence_id);
              return (
                <p
                  onClick={() => onRowClick(row)}
                  className={
                    !relatedResidence
                      ? 'text-red-500 underline '
                      : `${getResidenceStyle(relatedResidence)} rounded-full px-2 py-1`
                  }
                >
                  {relatedResidence?.name || 'Bando non trovato'}
                </p>
              );
            }
            return (
              <p onClick={() => onRowClick(row)} className="text-red-500 underline">
                Appello non trovato{' '}
              </p>
            );
          },
          sortable: true,
        },
        {
          name: 'Data appello',
          selector: (row) => {
            const relatedAppeal = appeals.find((appeal) => appeal._id === row.appeal_id);
            if (relatedAppeal) return dayjs(relatedAppeal.date).valueOf();
            return 'Appello non trovato';
          },
          cell: (row) => {
            const relatedAppeal = appeals.find((appeal) => appeal._id === row.appeal_id);
            if (relatedAppeal) return dayjs(relatedAppeal.date).format('DD/MM/YYYY');
            return (
              <p onClick={() => onRowClick(row)} className="text-red-500 underline">
                Appello non trovato{' '}
              </p>
            );
          },
          sortable: true,
        },
        {
          name: 'Data creazione',
          id: 'lastUpdate',
          selector: (row) => dayjs(row.createdAt).unix(),
          cell: (row) => dayjs(row.createdAt).format('DD/MM/YYYY HH:mm'),
          sortable: true,
        },
      ];
      setApplicationsColumns(parsedApplicationsColumns);
    }
  }, [applications, appeals, residences, users, getStatusStyle, onRowClick]);

  return { applicationsColumns };
};

export default useApplicationsColumns;
