import { useMutation } from "@tanstack/react-query"
import { downloadApplicationsCsvFn } from "api/applicationsApi"
import { FilterByAppeal, FilterByResidence, FilterByStatus, FilterByYear } from "components/Filters/Filters"
import SearchBar from "components/Input/SearchBar"
import NoDataComponent from "components/NoDataComponent"
import DataTableBase from "components/Shared/Table/Table"
import dayjs from "dayjs"
import { useAppealsQuery, useApplicationsQuery, useResidencesQuery, useUsersQuery } from "features/queries"
import fileDownload from "js-file-download"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { TableColumn } from "react-data-table-component"
import { FaEnvelopeOpenText, FaRegFileExcel } from "react-icons/fa"
import { useSearchParams } from "react-router-dom"
import { BeatLoader } from "react-spinners"
import { IApplicationStatus } from "types/api/applications/applications"
import { IApplication } from "types/api/applications/applications"
import { IResidence } from "types/api/residences"
import { IUser } from "types/api/users"
import sendNotification from "utils/notifications"

enum ApplicationTableFilters {
  RESIDENCE = "residence_id",
  APPEAL_YEAR = "appeal_year",
  APPEAL = "appeal",
  STATUS = "status",
  SEARCH = "search",
}

interface IApplicationsTable {
  onRowClick: (application: IApplication) => void
  applicationsColumns: TableColumn<IApplication>[]
}

const ApplicationsTable: React.FC<IApplicationsTable> = ({ onRowClick, applicationsColumns }) => {
  const { data: applications = [], isError: isApplicationsError } = useApplicationsQuery()
  const { data: appeals = [] } = useAppealsQuery()
  const { data: residences = [] } = useResidencesQuery()
  const { data: users = [] } = useUsersQuery()

  const [search, setSearch] = useSearchParams()

  const updateFilterSearchParams = useCallback(
    (param: ApplicationTableFilters) => (value: string) => {
      if (value !== "*") search.set(param, value)
      else search.delete(param)

      setSearch(search)
    },
    [search, setSearch]
  )

  const filteredAppeals = useMemo(
    () =>
      appeals.filter((appeal) => {
        const residenceFilter = search.get(ApplicationTableFilters.RESIDENCE)
        const yearFilter = search.get(ApplicationTableFilters.APPEAL_YEAR)
        return (
          (!residenceFilter || appeal.residence_id === residenceFilter) &&
          (!yearFilter || parseInt(yearFilter) === dayjs(appeal.date).year())
        )
      }),
    [search, appeals]
  )

  const filterApplicationByText = useCallback(
    (text: string, application: IApplication, residence?: IResidence, user?: IUser) => {
      const parsedSearchTerm = text.toLowerCase().trim()
      if (parsedSearchTerm === "") return true

      if (residence && residence.name.toLowerCase().includes(parsedSearchTerm)) return true

      if (application.registry) {
        const completeName = application.registry.firstName + application.registry.lastName
        if (completeName.toLowerCase().replace(/\s/g, "").includes(parsedSearchTerm.replace(/\s/g, ""))) return true
      }

      if (user) {
        if (user.email.toLowerCase().includes(parsedSearchTerm)) return true
      } else if ("utente non trovato".includes(parsedSearchTerm)) return true

      return false
    },
    []
  )

  const filteredApplications = useMemo(() => {
    const isFilteredByResidence = search.get(ApplicationTableFilters.RESIDENCE)
    const isFilteredByYear = search.get(ApplicationTableFilters.APPEAL_YEAR)
    const isFilteredByStatus = search.get(ApplicationTableFilters.STATUS)
    const isFilteredByAppeal = search.get(ApplicationTableFilters.APPEAL)
    const isFilteredByText = search.get(ApplicationTableFilters.SEARCH)

    return applications.filter((application) => {
      const applicationAppeal = appeals.find((appeal) => appeal._id === application.appeal_id)
      const applicationUser = users.find((user) => user._id === application.user_id)
      const appealResidence = residences.find((residence) => residence._id === applicationAppeal?.residence_id)

      const residenceFilter = !isFilteredByResidence || appealResidence?._id === isFilteredByResidence
      const yearFilter =
        !isFilteredByYear || (applicationAppeal && dayjs(applicationAppeal.date).year().toString() === isFilteredByYear)
      const searchFilter =
        !isFilteredByText || filterApplicationByText(isFilteredByText, application, appealResidence, applicationUser)
      const statusFilter =
        !isFilteredByStatus ||
        application.status === IApplicationStatus[isFilteredByStatus as keyof typeof IApplicationStatus]
      const appealFilter = !isFilteredByAppeal || applicationAppeal?._id === isFilteredByAppeal

      return residenceFilter && yearFilter && searchFilter && statusFilter && appealFilter
    })
  }, [search, filterApplicationByText, applications, residences, users, appeals])

  const [clearRows, setClearRows] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<IApplication[]>([])

  //clear selected
  useEffect(() => {
    setClearRows((cr) => !cr)
  }, [filteredApplications])

  const onDownloadApplicationsError = () =>
    sendNotification("Impossibile scaricare l'excel richiesto", "", "error", true, 5000)

  const { mutateAsync: downloadApplicationsAsync, isLoading: isCsvDownloadLoading } = useMutation(
    downloadApplicationsCsvFn,
    {
      onSettled: () => setClearRows(!clearRows),
    }
  )

  const onDownloadCsvClick = useCallback(
    async (applications: IApplication[], xlsx?: boolean) => {
      const applicationsIds = applications.map((appl) => appl._id)
      const filename = `${dayjs().format("YYYY_MM_DD")}_${applications.length}.${xlsx ? "xlsx" : "csv"}`
      const mimeType = xlsx ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "text/csv"
      try {
        const data = await downloadApplicationsAsync({ id: applicationsIds, xlsx })
        const file = new Blob([data], { type: mimeType })
        fileDownload(file, filename)
      } catch (e: unknown) {
        onDownloadApplicationsError()
      }
    },
    [downloadApplicationsAsync]
  )

  const contextActions = useMemo(
    () => (
      <div className="w-full flex items-center justify-between gap-4">
        <button
          disabled={isCsvDownloadLoading}
          onClick={() => {
            onDownloadCsvClick(selectedRows, true)
          }}
          className="green-button"
        >
          {!isCsvDownloadLoading ? (
            <div className="flex flex-row items-center">
              <FaRegFileExcel />
              <span className="ml-2">Esporta XLSX</span>
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <BeatLoader size={8} color="white" />
              <span className="ml-2">Download in corso...</span>
            </div>
          )}
        </button>
      </div>
    ),
    [isCsvDownloadLoading, onDownloadCsvClick, selectedRows]
  )

  const userActions = useMemo(
    () => (
      <div className="w-full flex flex-col lg:flex-row items-end justify-between mt-4 lg:mt-0 gap-8">
        {
          <SearchBar
            value={search.get(ApplicationTableFilters.SEARCH) || ""}
            onChange={updateFilterSearchParams(ApplicationTableFilters.SEARCH)}
            placeholder="Cerca tra la candidature..."
          />
        }
      </div>
    ),
    [updateFilterSearchParams, search]
  )

  //check selected appeal is always in range (appeal filter is influenced by year & residence)
  const selectedAppealFilter = useMemo(() => {
    const selected = search.get(ApplicationTableFilters.APPEAL)
    const selectedExist = filteredAppeals.find((appeal) => appeal._id === selected)
    return selectedExist?._id || "*"
  }, [search, filteredAppeals])

  const subHeader = useMemo(() => {
    return (
      <div className="w-full flex flex-col items-start">
        <div className="w-full flex flex-col lg:flex-row items-center justify-start mt-4 gap-8">
          <FilterByResidence
            residences={residences}
            selected={search.get(ApplicationTableFilters.RESIDENCE) || "*"}
            onChange={updateFilterSearchParams(ApplicationTableFilters.RESIDENCE)}
          />
          <FilterByAppeal
            selected={selectedAppealFilter}
            appeals={filteredAppeals}
            residences={residences}
            onChange={updateFilterSearchParams(ApplicationTableFilters.APPEAL)}
          />
          <FilterByYear
            appeals={appeals}
            selected={search.get(ApplicationTableFilters.APPEAL_YEAR) || "*"}
            onChange={updateFilterSearchParams(ApplicationTableFilters.APPEAL_YEAR)}
          />
          <FilterByStatus
            selected={search.get(ApplicationTableFilters.STATUS) || "*"}
            onChange={updateFilterSearchParams(ApplicationTableFilters.STATUS)}
          />
        </div>
        <p className="mt-6 text-md text-black">
          <span className="font-bold underline">{filteredApplications.length} candidature trovate</span>
        </p>
      </div>
    )
  }, [
    search,
    residences,
    appeals,
    filteredApplications,
    filteredAppeals,
    selectedAppealFilter,
    updateFilterSearchParams,
  ])

  return (
    <>
      <DataTableBase
        title={
          <div className="flex flex-row items-center justify-start gap-4 text-3xl font-bold">
            <FaEnvelopeOpenText />
            <h3>Candidature</h3>
          </div>
        }
        actions={userActions}
        subHeader
        subHeaderComponent={subHeader}
        noDataComponent={
          <NoDataComponent
            title="Nessuna candidatura trovata"
            description="C'è stato un errore nel recupero delle candidature"
            isError={isApplicationsError}
          />
        }
        columns={applicationsColumns}
        data={filteredApplications}
        pagination
        defaultSortAsc={false}
        defaultSortFieldId="lastUpdate"
        onRowClicked={onRowClick}
        pointerOnHover
        highlightOnHover
        selectableRows
        onSelectedRowsChange={(selected) => setSelectedRows(selected.selectedRows)}
        clearSelectedRows={clearRows}
        contextActions={contextActions}
      />
    </>
  )
}

export default ApplicationsTable
