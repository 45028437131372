import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import Login from 'pages/Auth/Login';
import Activation from 'pages/Auth/ActivateAccount';
import ResetPassword from 'pages/Auth/ResetAccountPassword';

import Appeals from 'pages/Appeals';
import Applications from 'pages/Applications';
import Residences from 'pages/Residences';
import Users from 'pages/Users';

import { ROUTES } from 'appRoutes';
import React from 'react';
import { getUserInCookie } from 'utils/cookies';
import { useUser } from 'context/userContext';
import { FaviconLoader } from 'components/Shared/Spinner/FaviconLoader';
import { IAuthMeResponse } from 'types/api/auth';
import Layout from 'components/Layout/Layout';
import { useAuthUserQuery } from 'features/queries';

function Router() {
  return (
    <div className="px-6 xl:px-8">
      <Routes>
        <Route path="/" element={<Navigate to="/users" replace />} />
        <Route path={ROUTES.AUTH_LOGIN} element={<Login />} />
        <Route path={ROUTES.AUTH_ACTIVATION} element={<Activation />} />
        <Route path={ROUTES.AUTH_RESETPASSWORD} element={<ResetPassword />} />
        <Route element={<RequireUser />}>
          <Route path={ROUTES.APPEALS} element={<Appeals />} />
          <Route path={ROUTES.APPLICATIONS} element={<Applications />} />
          <Route path={ROUTES.RESIDENCES} element={<Residences />} />
        </Route>
        <Route element={<RequireUser requireSuperAdmin={true} />}>
          <Route path={ROUTES.USERS} element={<Users />} />
        </Route>
        <Route path="*" element={<Navigate to={ROUTES.AUTH_LOGIN} replace />} />
      </Routes>
    </div>
  );
}

interface IRequireUser {
  requireSuperAdmin?: boolean;
}
const RequireUser: React.FC<IRequireUser> = ({ requireSuperAdmin = false }) => {
  const userInCookie = getUserInCookie();
  const location = useLocation();
  const { state, dispatch } = useUser();
  const { authUser: stateUser } = state;

  const onSuccess = (data: IAuthMeResponse) => dispatch({ type: 'SET_USER', payload: data });

  const { isLoading, isFetching } = useAuthUserQuery({ onSuccess, enabled: !!userInCookie });

  const loading = isLoading || isFetching;

  console.log(userInCookie);
  if (loading && userInCookie) {
    return <FaviconLoader />;
  }

  const hasPermission = !requireSuperAdmin || stateUser?.adminPermissions === '*';

  if (userInCookie || stateUser) {
    if (hasPermission)
      return (
        <Layout>
          <Outlet />
        </Layout>
      );
    return <Navigate to={{ pathname: '/residences' }} replace />;
  }

  return <Navigate to={ROUTES.AUTH_LOGIN} state={{ from: location }} replace />;
};

export default Router;
