import { Disclosure, Transition } from "@headlessui/react"
import dayjs from "dayjs"
import React from "react"
import { FaChevronDown } from "react-icons/fa"
import { IAppeal } from "types/api/appeals"
import { IApplication } from "types/api/applications/applications"
import { IResidence } from "types/api/residences"

interface IAppealDisclosure {
  application: IApplication
  appeal?: IAppeal
  residence?: IResidence
}
const AppealDisclosure: React.FC<IAppealDisclosure> = ({ application, appeal, residence }) => {
  const getDisclosureStyle = (isOpen: boolean) => {
    if (isOpen && residence && !appeal) return "bg-bluemazza text-white rounded-t hover:bg-bluemazza hover:text-white"
    if (residence && appeal) return "text-bluemazza bg-white rounded hover:bg-bluemazza hover:text-white"
    return "bg-red-500 text-white underline hover:bg-white hover:text-red-500"
  }

  const residenceText = residence?.name || "Bando non trovata"
  const appealText = appeal ? dayjs(appeal.date).format("DD/MM/YYYY") : "Appello non trovato"
  const appealDate = appeal
    ? `${dayjs(appeal.date).format("DD/MM/YYYY")} - ${dayjs(appeal.date).fromNow()}`
    : "Appello non trovato"
  const appealExpiryDate = appeal
    ? `${dayjs(appeal.expiryDate).format("DD/MM/YYYY")} - ${dayjs(appeal.expiryDate).fromNow()}`
    : "Appello non trovato"

  return (
    <div className="w-full mx-auto bg-white rounded">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`transition-colors ease-in-out duration-300 flex justify-between w-full px-4 py-2 text-sm font-medium text-left shadow border border-bluemazza
              ${getDisclosureStyle(open)}`}
            >
              {residenceText} ({appealText})
              <FaChevronDown className={`${open ? " transform rotate-180" : ""} w-5 h-5`} />
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-black border border-bluemazza rounded-b">
                <div className="w-full">
                  <div className="w-full flex flex-row items-center justify-between">
                    <div className="flex flex-col items-start">
                      <label className="form-label" htmlFor="residenceName">
                        Bando
                      </label>
                      <p className={`font-medium text-md mt-0 ${!residence && "text-red-500 underline"}`}>
                        {residenceText}
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <label className="form-label" htmlFor="appealDate">
                        Data
                      </label>
                      <p className={`font-medium text-md mt-0 ${!appeal && "text-red-500 underline"}`}>{appealDate}</p>
                    </div>
                    <div className="flex flex-col items-start">
                      <label className="form-label" htmlFor="appealExpiry">
                        Scadenza
                      </label>
                      <p className={`font-medium text-md mt-0 ${!appeal && "text-red-500 underline"}`}>
                        {appealExpiryDate}
                      </p>
                    </div>
                  </div>
                  {residence?.promoCodeEnabled && (
                    <div className="flex flex-row mt-4">
                      <label className="form-label" htmlFor="promoCode">
                        Codice Promozionale
                      </label>
                      <p className="font-medium text-md ml-4 underline ">{application.promoCode || "Non fornito"}</p>
                    </div>
                  )}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default AppealDisclosure
