import { useCallback, useState } from 'react';
import NewAdminDialog from 'components/Users/NewAdminDialog/NewAdminDialog';
import UserDetailDialog from 'components/Users/UserDetailDialog/UserDetailDialog';
import { IUser } from 'types/api/users';
import UsersTable from 'components/Users/UsersTable/UsersTable';
import useUsersColumns from 'hooks/useUsersColumns';

/**
 * Renders the Users page
 */

export default function Users() {
  const [isNewUserDialogOpen, setIsNewUserDialogOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);

  const onUserDetailOpen = useCallback((user: IUser) => setSelectedUser(user), []);
  const onUserDetailClose = useCallback(() => setSelectedUser(undefined), []);

  const onNewUserOpen = useCallback(() => setIsNewUserDialogOpen(true), []);
  const onNewUserClose = useCallback(() => setIsNewUserDialogOpen(false), []);

  const { usersColumns } = useUsersColumns({
    onRowClick: onUserDetailOpen,
  });

  return (
    <>
      <NewAdminDialog isOpen={isNewUserDialogOpen} onClose={onNewUserClose} />
      {selectedUser && <UserDetailDialog isOpen onClose={onUserDetailClose} user={selectedUser} />}
      <UsersTable columns={usersColumns} openNewUser={onNewUserOpen} openUserDetail={onUserDetailOpen} />
    </>
  );
}
