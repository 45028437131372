import { Disclosure, Transition } from "@headlessui/react"
import dayjs from "dayjs"
import React from "react"
import { FaChevronDown } from "react-icons/fa"
import { UserProfileSchema } from "types/api/applications/userProfile"

interface IRegistryDisclosure {
  userProfile: UserProfileSchema
}
const RegistryDisclosure: React.FC<IRegistryDisclosure> = ({ userProfile }) => {
  const registry = userProfile?.registry || null

  return (
    <div className="w-full mx-auto bg-white rounded">
      {!registry ? (
        <p className="font-medium text-sm text-bluemazza">
          L&#8203;utente non ha ancora inserito alcun dato anagrafico
        </p>
      ) : (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`transition-colors ease-in-out duration-300 flex justify-between w-full px-4 py-2 text-sm font-medium text-left shadow border border-bluemazza ${
                  open ? "bg-bluemazza text-white rounded-t" : "text-bluemazza bg-white rounded"
                } hover:bg-bluemazza hover:text-white`}
              >
                <div className="w-full flex flex-row justify-between items-center mr-4">
                  <p>
                    {" "}
                    {`${registry.firstName} ${registry.lastName} (${dayjs(registry.birthday)
                      .utc()
                      .format("DD/MM/YYYY")})`}{" "}
                  </p>
                </div>
                <FaChevronDown className={`${open ? " transform rotate-180" : ""} w-5 h-5`} />
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-black border border-bluemazza rounded-b">
                  <div className="w-full flex flex-row items-center justify-between">
                    <div>
                      <label className="form-label">Nome</label>
                      <p>{registry.firstName}</p>
                    </div>
                    <div>
                      <label className="form-label">Cognome</label>
                      <p>{registry.lastName}</p>
                    </div>
                    <div>
                      <label className="form-label">Data di nascita</label>
                      <p>{dayjs(registry.birthday).utc().format("DD/MM/YYYY")}</p>
                    </div>
                    <div>
                      <label className="form-label">Codice fiscale</label>
                      <p>{registry.fiscalCode}</p>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between mt-4">
                    <div>
                      <label className="form-label">Cittadinanza</label>
                      <p>{registry.citizenship}</p>
                    </div>
                    <div>
                      <label className="form-label">Seconda Cittadinanza</label>
                      <p>{registry.secondCitizenship || "Non fornita"}</p>
                    </div>
                    <div>
                      <label className="form-label">ISEE</label>
                      <p>{registry.notSubmitIsee ? "Non presentato" : registry.isee || "Non fornito"}</p>
                    </div>
                  </div>
                  <div className="w-full h-0.5 mt-4 bg-bluemazza" />
                  <div className="flex flex-row items-center justify-between mt-4">
                    <div>
                      <label className="form-label">Indirizzo</label>
                      <p>
                        {registry.address.street} {registry.address.number}, {registry.address.zipCode}{" "}
                        {registry.address.city} ({registry.address.district})
                      </p>
                    </div>
                    <div>
                      <label className="form-label">Cellulare</label>
                      <p>{registry.cellNumber}</p>
                    </div>
                    <div>
                      <label className="form-label">Telefono</label>
                      <p>{registry.phoneNumber || "Non fornito"}</p>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      )}
    </div>
  )
}

export default RegistryDisclosure
