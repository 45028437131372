export enum ROUTES {
  AUTH_LOGIN = '/auth/login',
  AUTH_ACTIVATION = 'auth/activation/:userId/:verificationCode',
  AUTH_RESETPASSWORD = 'auth/resetPassword/:userId/:resetPasswordCode',
  APPEALS = '/appeals',
  APPLICATIONS = '/applications',
  RESIDENCES = '/residences',
  USERS = '/users',
}

export type RoutesType =
  | { path: ROUTES.AUTH_LOGIN }
  | {
      path: ROUTES.AUTH_ACTIVATION;
      params: { userId: string; verificationCode: string };
    }
  | {
      path: ROUTES.AUTH_RESETPASSWORD;
      params: { userId: string; resetPasswordCode: string };
    }
  | { path: ROUTES.APPEALS }
  | { path: ROUTES.APPLICATIONS }
  | { path: ROUTES.RESIDENCES }
  | { path: ROUTES.USERS };
