import {
  IActivateUserRequest,
  IAuthMeResponse,
  ILoginRequest,
  ILoginResponse,
  IRegisterUserRequest,
  IResetPasswordRequest,
  IVerifyUserRequest,
} from "types/api/auth"
import baseApi from "./baseApi"

export async function registerFn(registerBody: IRegisterUserRequest) {
  const response = await baseApi.post("/auth", registerBody)
  return response.data
}
export async function authMe(): Promise<IAuthMeResponse> {
  const response = await baseApi.get("/auth/me")
  return response.data
}
export async function loginFn(loginBody: ILoginRequest): Promise<ILoginResponse> {
  const response = await baseApi.post("/auth/login", loginBody)
  return response.data
}

export async function verifyUserFn(verifyBody: IVerifyUserRequest) {
  const response = await baseApi.post("/auth/verify", verifyBody)
  return response.data
}

export async function activateUserFn(activateBody: IActivateUserRequest) {
  const response = await baseApi.post("/backoffice/auth/activate", activateBody)
  return response.data
}

export async function forgotPasswordFn(email: string) {
  const response = await baseApi.post("/auth/forgotPassword", { email })
  return response.data
}

export async function resetPasswordFn(resetPasswordBody: IResetPasswordRequest) {
  const response = await baseApi.post("/auth/resetPassword", resetPasswordBody)
  return response.data
}
