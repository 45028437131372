import React, { useCallback, useState } from 'react';
import { IAppeal } from 'types/api/appeals';
import AppealDetailDialog from 'components/Appeals/AppealDetailDialog/AppealDetailDialog';
import DeleteAppealDialog from 'components/Appeals/DeleteAppealDialog/DeleteAppealDialog';
import NewAppealDialog from 'components/Appeals/NewAppealDialog/NewAppealDialog';
import AppealsTable from 'components/Appeals/AppealsTable/AppealsTable';
import useAppealsColumns from 'hooks/useAppealsColumns';

/**
 * Renders the Appeals page
 */
export default function Appeals() {
  const [isNewAppealDialogOpen, setIsNewAppealDialogOpen] = useState<boolean>(false);
  const [selectedAppeal, setSelectedAppeal] = useState<IAppeal | undefined>(undefined);
  const [appealToDelete, setAppealToDelete] = useState<IAppeal | undefined>(undefined);

  const onAppealDetailOpen = useCallback((appeal: IAppeal) => setSelectedAppeal(appeal), []);
  const onAppealDetailClose = useCallback(() => setSelectedAppeal(undefined), []);

  const onDeleteAppealOpen = useCallback((appeal: IAppeal) => setAppealToDelete(appeal), []);
  const onDeleteAppealClose = useCallback(() => setAppealToDelete(undefined), []);

  const onNewAppealOpen = useCallback(() => setIsNewAppealDialogOpen(true), []);
  const onNewAppealClose = useCallback(() => setIsNewAppealDialogOpen(false), []);

  const { appealsColumns } = useAppealsColumns({
    onRowClick: onAppealDetailOpen,
    onDeleteClick: onDeleteAppealOpen,
  });

  return (
    <>
      {selectedAppeal && <AppealDetailDialog isOpen={true} onClose={onAppealDetailClose} appeal={selectedAppeal} />}
      {appealToDelete && <DeleteAppealDialog isOpen={true} onClose={onDeleteAppealClose} appeal={appealToDelete} />}
      <NewAppealDialog isOpen={isNewAppealDialogOpen} onClose={onNewAppealClose} />
      <AppealsTable columns={appealsColumns} openNewAppeal={onNewAppealOpen} openAppealDetail={onAppealDetailOpen} />
    </>
  );
}
