import { Disclosure, Transition } from '@headlessui/react';
import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { IAdmRequiredDocument, IResidence } from 'types/api/residences';

interface IResidenceAttachmentsView {
  residence: IResidence;
}
const ResidenceAttachmentsComponent: React.FC<IResidenceAttachmentsView> = ({ residence }) => {
  const RequiredAttachment = (doc: IAdmRequiredDocument) => {
    const { alreadyEnrolled, firstEnrollment, hasBachelor } = doc.required;

    if (alreadyEnrolled && firstEnrollment && hasBachelor)
      return <p className="text-green-500 underline">Sempre richiesto</p>;

    if (!alreadyEnrolled && !firstEnrollment && !hasBachelor)
      return <p className="text-red-500 underline">Mai richiesto</p>;

    return <p className="text-orange-500 underline">Non sempre richiesto</p>;
  };

  return (
    <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
      {residence.admRequiredDocuments.map((doc) => {
        return (
          <div key={doc.key} className="w-full mx-auto bg-white rounded">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`transition-colors ease-in-out duration-300 flex justify-between w-full px-4 py-2 text-sm font-medium text-left shadow border border-bluemazza ${
                      open ? 'bg-bluemazza text-white rounded-t' : 'text-bluemazza bg-white rounded'
                    } hover:bg-bluemazza hover:text-white`}
                  >
                    <div className="w-full flex flex-row justify-between items-center mr-4">
                      <p> {doc.textToDisplay} </p> {RequiredAttachment(doc)}
                    </div>
                    <FaChevronDown className={`${open ? ' transform rotate-180' : ''} w-5 h-5`} />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-black border border-bluemazza rounded-b">
                      <div className="w-full flex flex-col items-start justify-between">
                        <div className="flex flex-row items-center">
                          <label className="form-label" style={{ marginBottom: 0 }}>
                            Chiave
                          </label>
                          <p className="ml-2">{doc.key}</p>
                        </div>
                        <div className="w-full h-0.5 my-2 bg-bluemazza" />
                        <div className="flex flex-row items-center">
                          <label className="form-label" style={{ marginBottom: 0 }}>
                            Prima iscrizione universitaria
                          </label>
                          <p className={`ml-2 ${doc.required.firstEnrollment ? 'text-green-500' : 'text-red-500'}`}>
                            {doc.required.firstEnrollment ? 'SI' : 'NO'}
                          </p>
                        </div>
                        <div className="flex flex-row items-center">
                          <label className="form-label" style={{ marginBottom: 0 }}>
                            Già iscritto all&#39;università
                          </label>
                          <p className={`ml-2 ${doc.required.alreadyEnrolled ? 'text-green-500' : 'text-red-500'}`}>
                            {doc.required.alreadyEnrolled ? 'SI' : 'NO'}
                          </p>
                        </div>
                        <div className="flex flex-row items-center">
                          <label className="form-label" style={{ marginBottom: 0 }}>
                            In possesso di laurea triennale
                          </label>
                          <p className={`ml-2 ${doc.required.hasBachelor ? 'text-green-500' : 'text-red-500'}`}>
                            {doc.required.hasBachelor ? 'SI' : 'NO'}
                          </p>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </div>
        );
      })}
    </div>
  );
};

export default ResidenceAttachmentsComponent;
