import { Transition, Disclosure } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { downloadApplicationAttachmentFn } from 'api/applicationsApi';
import fileDownload from 'js-file-download';
import React, { useCallback, useMemo } from 'react';
import { FaChevronDown, FaDownload } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { IApplication } from 'types/api/applications/applications';
import { UserProfileSchema } from 'types/api/applications/userProfile';
import { IAdmRequiredDocument, IResidence, ResidenceMode } from 'types/api/residences';
import sendNotification from 'utils/notifications';

interface IUploadsDisclosure {
  application: IApplication;
  appealResidence?: IResidence;
  userProfile?: UserProfileSchema;
}

const UploadsDisclosure: React.FC<IUploadsDisclosure> = ({ application, appealResidence, userProfile }) => {
  console.log(appealResidence, userProfile);
  const requiredUploads = useMemo(() => {
    if (appealResidence && userProfile) {
      const isFirstEnrollment =
        appealResidence.mode !== ResidenceMode.DEFAULT
          ? true
          : userProfile.curriculum?.university.firstUniEnrollment
          ? true
          : false;
      const isAlreadyEnrolled =
        appealResidence.mode !== ResidenceMode.DEFAULT
          ? true
          : userProfile.curriculum?.university.alreadyEnrolled
          ? true
          : false;
      const hasBachelor =
        appealResidence.mode !== ResidenceMode.DEFAULT
          ? true
          : userProfile.curriculum?.university.alreadyEnrolled?.hasBachelor
          ? true
          : false;
      const parsedUploads: IAdmRequiredDocument[] = [];
      appealResidence.admRequiredDocuments.forEach((reqUpload) => {
        // se l'utente dichiara di non inviare isee viene rimosso isee e dsu
        if ((reqUpload.key === 'isee' || reqUpload.key === 'dsu') && userProfile.registry?.notSubmitIsee) {
          // skip it
        } else if (
          appealResidence.mode !== ResidenceMode.INTERNATIONAL &&
          reqUpload.key === 'bachelorDegreeCertificate' &&
          !userProfile.curriculum?.degreeCertificate
        ) {
          // skip it
        } else if (reqUpload.key === 'diploma' && !userProfile.curriculum?.highSchoolCertificate) {
          // skip it
        } else if (
          (reqUpload.required.alreadyEnrolled && isAlreadyEnrolled) ||
          (reqUpload.required.firstEnrollment && isFirstEnrollment) ||
          (reqUpload.required.hasBachelor && hasBachelor)
        )
          parsedUploads.push(reqUpload);
      });
      return parsedUploads;
    }
  }, [appealResidence, userProfile]);

  const requiredUploadedDocs = useMemo(() => {
    if (requiredUploads)
      return application.uploads.filter((upload) => requiredUploads.find((doc) => doc.key === upload.key));
  }, [requiredUploads, application]);

  //Not required uploads which has been uploaded anyway (due to appeal change for ex.)
  //   const nonRequiredUploadedDocs = useMemo(() => {
  //     if (requiredUploads)
  //       return application.uploads.filter(
  //         (upload) => !requiredUploads.find((requiredDoc) => requiredDoc.key === upload.key)
  //       )
  //   }, [requiredUploads, application])

  const isAttachmentsLoadingOk = requiredUploads?.length && requiredUploadedDocs;

  return (
    <div className="w-full mx-auto bg-white rounded">
      {!isAttachmentsLoadingOk ? (
        <p className="font-medium text-sm text-bluemazza">
          Non è stato possibile generare gli allegati richiesti (il curriculum è incompleto)
        </p>
      ) : (
        <Disclosure>
          {({ open }) => {
            const requiredUploadedPercentage = (requiredUploadedDocs.length / requiredUploads.length) * 100;
            return (
              <>
                <Disclosure.Button
                  className={`transition-colors ease-in-out duration-300 flex justify-between w-full px-4 py-2 text-sm font-medium text-left shadow border border-bluemazza ${
                    open ? 'bg-bluemazza text-white rounded-t' : 'text-bluemazza bg-white rounded'
                  } hover:bg-bluemazza hover:text-white`}
                >
                  <div className="w-full flex flex-row justify-between items-center mr-4">
                    <p>
                      {requiredUploadedDocs.length}/{requiredUploads.length} ({requiredUploadedPercentage.toFixed(1)}%)
                    </p>
                    <p>Allegati richiesti generati in base al bando scelto + curriculum</p>
                  </div>
                  <FaChevronDown className={`${open ? ' transform rotate-180' : ''} w-5 h-5`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500 border border-bluemazza rounded-b">
                    <div className="flex flex-col items-start justify-between">
                      {requiredUploads.map((upload) => (
                        <UploadsDisclosureRow key={upload.key} application={application} upload={upload} />
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            );
          }}
        </Disclosure>
      )}
    </div>
  );
};

interface IUploadsDisclosureRow {
  application: IApplication;
  upload: IAdmRequiredDocument;
}
const UploadsDisclosureRow: React.FC<IUploadsDisclosureRow> = ({ application, upload }) => {
  const userHasUpload = application.uploads.find((uploadedDoc) => uploadedDoc.key === upload.key);

  const onDownloadAttachmentSuccess = (data: any) => {
    if (userHasUpload) {
      const file = new Blob([data], { type: userHasUpload.mimetype });
      fileDownload(file, `${userHasUpload.originalName}`);
    }
  };
  const onDownloadAttachmentError = () => sendNotification("Impossibile scaricare l'allegato", '', 'error', true, 5000);

  const { mutate: downloadAttachment, isLoading } = useMutation(downloadApplicationAttachmentFn, {
    onSuccess: onDownloadAttachmentSuccess,
    onError: onDownloadAttachmentError,
  });

  const onDownloadClick = useCallback(() => {
    if (userHasUpload) downloadAttachment({ id: application._id, uploadKey: userHasUpload.key });
  }, [downloadAttachment, userHasUpload, application]);

  return (
    <div className="w-full flex flex-row justify-between items-center">
      <div className="flex flex-row gap-4 items-center my-2">
        <div className={`w-2 h-2 rounded ${userHasUpload ? 'bg-green-500' : 'bg-red-500'}`} />
        <label className="form-label" htmlFor="passwordConfirmation" style={{ marginBottom: 0 }}>
          {upload.textToDisplay}
        </label>
      </div>
      <button
        disabled={!userHasUpload}
        onClick={onDownloadClick}
        className="flex flex-row gap-2 items-center bluemazza-button text-sm p-2"
      >
        {isLoading ? <BeatLoader color="#0064a9" size={5} /> : <FaDownload />}
      </button>
    </div>
  );
};

export default UploadsDisclosure;
