import { boolean, number, object, string, z } from 'zod';
import { RegistrySchema } from './registry';

export interface IDeleteApplication {
  id: string;
}

export interface IDownloadApplicationAttachment {
  id: string;
  uploadKey: string;
}

export interface IDownloadApplicationPdf {
  id: string;
}

export interface IDownloadApplicationsCsv {
  id: string[];
  xlsx?: boolean;
}

export interface IUpdateApplication {
  id: string;
  data: {
    appeal_id: string;
    status: IApplicationFinalStatus;
  };
}

export enum IApplicationStatus {
  APPLICATION_CREATED = 'Domanda creata',
  APPLICATION_COMPLETE = 'Domanda completa',
  APPLICATION_ACCEPTED = 'Invitato al colloquio',
  APPLICATION_REJECTED = 'Domanda rifiutata/ritarata',
  PRESENT = 'Presente al concorso',
  WITHDRAWN = 'Ritirato dal concorso',
  WINNER = 'Vincitore del concorso',
  LOSER = 'Non vincitore del concorso',
}

export enum IApplicationFinalStatus {
  APPLICATION_ACCEPTED = 'Invitato al colloquio',
  APPLICATION_REJECTED = 'Domanda rifiutata/ritarata',
  PRESENT = 'Presente al concorso',
  WITHDRAWN = 'Ritirato dal concorso',
  WINNER = 'Vincitore del concorso',
  LOSER = 'Non vincitore del concorso',
}

export interface IAdmRequiredDocument {
  key: string;
  required: {
    firstEnrollment: boolean;
    alreadyEnrolled: boolean;
    hasBachelor: boolean;
  };
  textToDisplay: string;
}

export const fileSchema = object({
  key: string(),
  name: string(),
  originalName: string(),
  size: number(),
  encoding: string(),
  truncated: boolean(),
  mimetype: string(),
  md5: string(),
});
export type FileSchema = z.infer<typeof fileSchema>;

export interface IApplication {
  _id: string;
  user_id: string;
  promoCode?: string;
  appeal_id: string;
  status: IApplicationStatus;
  uploads: FileSchema[];
  createdAt: Date;
  updatedAt: Date;
  registry?: RegistrySchema;
}
