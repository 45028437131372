import React, { useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { IUser } from 'types/api/users';

interface IuseAppealsColumns {
  onRowClick: (appeal: IUser) => void;
}
const useUsersColumns = ({ onRowClick }: IuseAppealsColumns) => {
  const [usersColumns, setUsersColumns] = useState<TableColumn<IUser>[]>([]);

  useEffect(() => {
    const parsedUsersColumns: TableColumn<IUser>[] = [
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: 'Verificato',
        selector: (row) => row.verified,
        cell: (row) =>
          row.verified ? (
            <FaCheckCircle onClick={() => onRowClick(row)} color="green" />
          ) : (
            <FaExclamationCircle onClick={() => onRowClick(row)} color="orange" />
          ),
        sortable: true,
      },
      {
        name: 'Tipologia Utente',
        selector: (row) =>
          !row.isAdmin ? 'Normale' : row.adminPermissions === '*' ? 'Amm. generale' : 'Amm. di residenza',
        cell: (row) =>
          !row.isAdmin ? (
            <p onClick={() => onRowClick(row)} className="text-orange-500">
              Normale
            </p>
          ) : row.adminPermissions === '*' ? (
            <p onClick={() => onRowClick(row)} className="text-green-500 font-bold">
              Amm. generale
            </p>
          ) : (
            <p onClick={() => onRowClick(row)} className="text-green-500 font-medium">
              Amm. di residenza
            </p>
          ),
        sortable: true,
      },
    ];
    setUsersColumns(parsedUsersColumns);
  }, [onRowClick]);

  return { usersColumns };
};

export default useUsersColumns;
