import SelectFilter from "components/Shared/Select/Select"
import React from "react"
import { IAppeal } from "types/api/appeals"
import { IApplicationStatus } from "types/api/applications/applications"
import { IResidence } from "types/api/residences"
import dayjs from "utils/dayjs"

interface IFilterByResidence {
  residences: IResidence[]
  selected: string
  onChange: (value: string) => void
}
export const FilterByResidence: React.FC<IFilterByResidence> = ({ residences, selected, onChange }) => {
  const options = residences.map((residence) => ({
    value: residence._id,
    label: residence.name,
  }))
  return (
    <SelectFilter
      label="Filtra per bando"
      options={options}
      value={selected}
      onChange={onChange}
      selectId="filterByResidence"
    />
  )
}

interface IFilterByAppeal {
  selected: string
  onChange: (value: string) => void
  appeals: IAppeal[]
  residences: IResidence[]
}
export const FilterByAppeal: React.FC<IFilterByAppeal> = ({ selected, onChange, appeals, residences }) => {
  const options = [{ label: " Tutti gli Appelli", value: "*" }]
  const appealsOptions = appeals.map((appeal) => {
    const relatedResidence = residences.find((residence) => residence._id === appeal.residence_id)
    return {
      value: appeal._id,
      label: `${relatedResidence?.name || "Bando non trovata"} - ${dayjs(appeal.date).format("DD/MM/YYYY")}`,
    }
  })

  return (
    <SelectFilter
      label="Filtra per Appello"
      options={options.concat(...appealsOptions)}
      value={selected}
      onChange={onChange}
      selectId="filterByAppeal"
    />
  )
}

interface IFilterByYear {
  appeals: IAppeal[]
  selected: string
  onChange: (value: string) => void
}
export const FilterByYear: React.FC<IFilterByYear> = ({ appeals, selected, onChange }) => {
  let appealYears: number[] = []
  if (appeals.length) {
    const minAppealYear = appeals.reduce((prev: IAppeal, curr: IAppeal) =>
      dayjs(prev.date) < dayjs(curr.date) ? prev : curr
    )

    const maxAppealYear = appeals.reduce((prev: IAppeal, curr: IAppeal) =>
      dayjs(prev.date) > dayjs(curr.date) ? prev : curr
    )

    const range = (min: number, max: number) => Array.from({ length: max - min + 1 }, (_, i) => min + i)

    appealYears = range(dayjs(minAppealYear.date).year(), dayjs(maxAppealYear.date).year())
  }

  const appealsOptions = appealYears.map((item) => ({ label: item.toString(), value: item.toString() }))
  const options: { label: string; value: number | string }[] = [{ label: "Tutti gli anni", value: "*" }].concat(
    ...appealsOptions
  )

  return (
    <SelectFilter
      label="Filtra per Anno"
      options={options}
      value={selected}
      onChange={onChange}
      selectId="filterByYear"
    />
  )
}

interface IFilterByStatus {
  selected: string
  onChange: (value: string) => void
}
export const FilterByStatus: React.FC<IFilterByStatus> = ({ selected, onChange }) => {
  const statusOptions = Object.keys(IApplicationStatus).map((status_key) => ({
    value: status_key,
    label: IApplicationStatus[status_key as keyof typeof IApplicationStatus],
  }))

  const options: { label: string; value: number | string }[] = [{ label: "Tutti gli stati", value: "*" }].concat(
    ...statusOptions
  )
  return (
    <SelectFilter
      label="Filtra per Stato"
      options={options}
      value={selected}
      onChange={onChange}
      selectId="filterByStatus"
    />
  )
}
