import { Dialog, Transition } from "@headlessui/react"
import React, { Fragment } from "react"
import { FaRegWindowClose } from "react-icons/fa"

type IType = "info" | "danger" | "warning" | "success"
interface IModal {
  isOpen: boolean
  onClose: () => void
  title: React.ReactNode
  children: React.ReactNode
  type?: IType
  size?: "sm" | "md" | "lg" | "xl"
  showCloseIcon?: boolean
}

const getHeaderColor = (type: IType) => {
  switch (type) {
    case "info":
      return "bg-bluemazza"
    case "danger":
      return "bg-red-500"
    case "warning":
      return "bg-orange-500"
    case "success":
      return "bg-green-500"
    default:
      return "bg-bluemazza"
  }
}
const Modal: React.FC<IModal> = ({
  isOpen,
  onClose,
  title,
  children,
  type = "info",
  size = "md",
  showCloseIcon = false,
}) => {
  const headerColor = getHeaderColor(type)
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen mx-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={`inline-block w-full max-w-${size} overflow-visible text-left align-middle transition-all transform bg-white shadow-xl rounded`}
            >
              <div
                className={`flex flex-row justify-between p-4 ${headerColor} text-xl font-bold leading-6 text-white rounded-t`}
              >
                <Dialog.Title as="h3">{title}</Dialog.Title>
                {showCloseIcon && <FaRegWindowClose className="text-2xl cursor-pointer" onClick={onClose} />}
              </div>

              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
