import React from 'react';
import { IAuthMeResponse } from 'types/api/auth';
import { removeUserInCookie } from 'utils/cookies';

type State = {
  authUser: IAuthMeResponse | null;
};

type Action = {
  type: 'SET_USER' | 'LOGOUT';
  payload: IAuthMeResponse | null;
};

type Dispatch = (action: Action) => void;

const initialState: State = {
  authUser: null,
};

type UserContextProviderProps = { children: React.ReactNode };

const UserContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const stateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case 'LOGOUT': {
      removeUserInCookie();
      return { authUser: null };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [state, dispatch] = React.useReducer(stateReducer, initialState);
  const value = { state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = React.useContext(UserContext);

  if (!context) throw new Error(`useUserContext must be used within a UserContextProvider`);
  return context;
};

export { UserContextProvider, useUser };
