import React from "react"
import { FaArrowLeft } from "react-icons/fa"
import { UserProfileSchema } from "types/api/applications/userProfile"

interface IEditModeApplicationDetailTitle {
  userProfile?: UserProfileSchema
  closeEditMode: () => void
}
const EditModeApplicationDetailTitle: React.FC<IEditModeApplicationDetailTitle> = ({ userProfile, closeEditMode }) => {
  return (
    <div className="w-full flex flex-row items-center justify-between">
      <div className="flex flex-col items-start">
        Modifica Candidatura {userProfile && `(${userProfile.registry?.firstName} ${userProfile.registry?.lastName})`}
      </div>
      <div
        onClick={closeEditMode}
        className="cursor-pointer flex flex-row items-center p-2 rounded text-md font-medium bluemazza-button"
      >
        <FaArrowLeft />
        <p className="pl-2">Torna indietro</p>
      </div>
    </div>
  )
}

export default EditModeApplicationDetailTitle
