import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime'; // ES 2015
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(updateLocale);
dayjs.extend(utc);
// dayjs.extend(timezone)
dayjs.extend(relativeTime);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'tra %s',
    past: '%s fa',
    s: 'qualche secondo',
    m: 'un minuto',
    mm: '%d minuti',
    h: 'un ora',
    hh: '%d ore',
    d: 'un giorno',
    dd: '%d giorni',
    M: 'un mese',
    MM: '%d mesi',
    y: 'un anno',
    yy: '%d anni',
  },
});

// dayjs.tz.setDefault('Europe/Rome')

export default dayjs;
