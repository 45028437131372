import { Tab } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUserFn } from 'api/usersApi';
import Modal from 'components/Shared/Modal/Modal';
import { ServerStateKeysEnum, useResidencesQuery } from 'features/queries';
import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaSave } from 'react-icons/fa';
import { ScaleLoader } from 'react-spinners';
import { IUser } from 'types/api/users';
import { Tooltip } from 'react-tooltip';
import sendNotification from 'utils/notifications';

interface IUserDetailDialog {
  isOpen: boolean;
  onClose: () => void;
  user: IUser;
}

const UserDetailDialog: React.FC<IUserDetailDialog> = ({ isOpen, onClose, user }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedResidences, setSelectedResidences] = useState<string[]>([]);

  const queryClient = useQueryClient();

  const { data: residences = [] } = useResidencesQuery();

  const onCreateUserSuccess = async (data: IUser) => {
    await queryClient.cancelQueries([ServerStateKeysEnum.USERS]);
    const previousValue = queryClient.getQueryData<IUser[]>([ServerStateKeysEnum.USERS]);

    if (previousValue) {
      const currentUserIndex = previousValue.findIndex((u) => user._id === u._id);
      const updatedValue = [...previousValue];
      updatedValue[currentUserIndex] = data;

      queryClient.setQueryData([ServerStateKeysEnum.USERS], [...updatedValue, data]);
      sendNotification('Utente aggiornato con successo', '', 'success', true, 5000);
    }
  };

  const onCreateUserError = () => sendNotification("Impossibile aggiornare l'utente", '', 'error', true, 5000);

  const { mutate: updateUserMutation, isLoading } = useMutation(updateUserFn, {
    onSuccess: onCreateUserSuccess,
    onError: onCreateUserError,
  });

  const handleResidenceChecked = (residence_id: string, checked: boolean) => {
    if (!checked && selectedResidences.includes(residence_id))
      setSelectedResidences(selectedResidences.filter((res_id) => res_id !== residence_id));
    if (checked && !selectedResidences.includes(residence_id))
      setSelectedResidences([...selectedResidences, residence_id]);
  };

  useEffect(() => {
    if (!user.isAdmin) {
      setSelectedIndex(0);
    }
    if (user.isAdmin && user.adminPermissions === '*') {
      setSelectedIndex(1);
    }
    if (user.isAdmin && Array.isArray(user.adminPermissions)) {
      setSelectedIndex(2);
      setSelectedResidences(user.adminPermissions);
    }
  }, [user]);

  const update = async () => {
    switch (selectedIndex) {
      case 0: // normale
        updateUserMutation({ id: user._id, data: { isAdmin: false, adminPermissions: [] } });
        break;
      case 1: // amm.generale
        updateUserMutation({ id: user._id, data: { isAdmin: true, adminPermissions: '*' } });
        break;
      case 2: // amm. di residenza
        updateUserMutation({ id: user._id, data: { isAdmin: true, adminPermissions: selectedResidences } });
        break;
    }
  };

  const modalTitle = <div className="w-full flex flex-row items-center justify-between">Dettagli utente</div>;
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={modalTitle}>
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        <div className="w-full">
          <label className="form-label" htmlFor="residence.name">
            Email
          </label>

          <p className="font-medium text-md mt-0">{user.email}</p>
        </div>
        <div
          data-tooltip-content={
            user.verified
              ? "L'utente è verificato, significa che ha confermato il suo account tramite mail"
              : "L'utente non è verificato, significa che deve ancora confermare il suo account tramite email"
          }
          id="userVerifiedIcon"
          className="w-full flex flex-row items-start gap-4"
        >
          <label className="form-label" htmlFor="residence.emails">
            Utente verificato
          </label>

          {user.verified ? <FaCheckCircle color="green" /> : <FaExclamationCircle color="orange" />}
        </div>
        <Tooltip place="top" className="text-white bg-bluemazza" anchorId="userVerifiedIcon" />

        <div className="w-full">
          <label className="form-label" htmlFor="residence.emails">
            Tiplogia utente
          </label>
        </div>
        <div className="w-full">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="flex  space-x-1 bg-white border rounded-xl">
              {['Normale', 'Amm. generale', 'Amm. di residenza'].map((name, idx) => (
                <Tab
                  key={idx}
                  className={({ selected }) =>
                    `w-full py-2 text-sm leading-5 font-medium rounded-lg
                      focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                      ${
                        selected
                          ? 'bg-bluemazza text-white'
                          : 'text-bluemazza bg-white hover:border hover:border-bluemazza'
                      }`
                  }
                >
                  {name}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel className="bg-white p-1 ">
                <p className="text-md font-medium underline">L&#8203;utente non ha alcun potere amministrativo.</p>
              </Tab.Panel>
              <Tab.Panel className="bg-white p-1">
                <p className="text-md font-medium underline">
                  L&#8203;utente può gestire tutti bandi ed i rispettivi utenti.
                </p>
              </Tab.Panel>
              <Tab.Panel className="bg-white p-1">
                <div className="w-full">
                  <label className="form-label" htmlFor="passwordConfirmation">
                    Bandi associati
                  </label>
                  {user.adminPermissions === '*' && (
                    <p className="font-medium text-sm mb-4">L&#8203;utente ha accesso a tutti i bandi</p>
                  )}
                  {residences.map((residence, idx) => {
                    const isChecked = selectedResidences.includes(residence._id);
                    return (
                      <div key={idx} className="flex flex-row gap-4 items-center">
                        <input
                          onChange={(e) => handleResidenceChecked(residence._id, e.target.checked)}
                          checked={isChecked}
                          type="checkbox"
                          className="form-input"
                        />
                        <p className="font-bold text-md">{residence.name}</p>
                      </div>
                    );
                  })}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>

        <div className="w-full flex flex-row items-center justify-center mt-4">
          <button onClick={update} className="bluemazza-button flex flex-row items-center gap-4" type="button">
            {isLoading ? (
              <ScaleLoader color="blue" />
            ) : (
              <>
                <FaSave />
                <p>Salva</p>
              </>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailDialog;
