import React, { useEffect, useState } from 'react';
import { useAppealsQuery, useApplicationsQuery, useResidencesQuery } from 'features/queries';
import { TableColumn } from 'react-data-table-component';
import dayjs from 'utils/dayjs';
import { FaTrashAlt } from 'react-icons/fa';
import { IAppeal } from 'types/api/appeals';

interface IuseAppealsColumns {
  onRowClick: (appeal: IAppeal) => void;
  onDeleteClick: (appeal: IAppeal) => void;
}
const useAppealsColumns = ({ onRowClick, onDeleteClick }: IuseAppealsColumns) => {
  const { data: appeals = [] } = useAppealsQuery();
  const { data: residences = [] } = useResidencesQuery();
  const { data: applications = [] } = useApplicationsQuery();

  const [appealsColumns, setAppealsColumns] = useState<TableColumn<IAppeal>[]>([]);

  interface IGetAppealStatus {
    selector: string;
    cell: React.ReactNode;
  }

  useEffect(() => {
    const getAppealStatus = (appeal: IAppeal): IGetAppealStatus => {
      if (dayjs(appeal.expiryDate) < dayjs())
        return {
          selector: 'Scaduto',
          cell: (
            <p onClick={() => onRowClick(appeal)} className="text-red-500">
              Scaduto
            </p>
          ),
        };
      if (dayjs(appeal.acceptCandidacyFromDate) < dayjs())
        return {
          selector: 'In partenza',
          cell: (
            <p onClick={() => onRowClick(appeal)} className="text-orange-500">
              In partenza
            </p>
          ),
        };
      return {
        selector: 'In corso',
        cell: (
          <p onClick={() => onRowClick(appeal)} className="text-green-500">
            In corso
          </p>
        ),
      };
    };

    if (appeals.length) {
      const parsedAppealsColumns: TableColumn<IAppeal>[] = [
        {
          name: 'Bando',
          selector: (row: IAppeal) => {
            const relatedResidence = residences.find((residence) => residence._id === row.residence_id);
            return relatedResidence?.name || 'Non trovata';
          },
          sortable: true,
        },
        {
          name: 'Stato',
          selector: (row: IAppeal) => getAppealStatus(row).selector,
          cell: (row: IAppeal) => getAppealStatus(row).cell,
          sortable: true,
        },
        {
          name: 'Data',
          selector: (row) => dayjs(row.date).valueOf(),
          cell: (row) => dayjs(row.date).format('DD/MM/YYYY'),
          sortable: true,
        },
        {
          name: 'Data di scadenza',
          selector: (row) => dayjs(row.expiryDate).valueOf(),
          cell: (row) => dayjs(row.expiryDate).format('DD/MM/YYYY'),
          sortable: true,
        },
        {
          name: 'Data di inizio',
          selector: (row) => (row.acceptCandidacyFromDate ? dayjs(row.acceptCandidacyFromDate).valueOf() : 'ND'),
          cell: (row) => (row.acceptCandidacyFromDate ? dayjs(row.acceptCandidacyFromDate).format('DD/MM/YYYY') : 'ND'),
          sortable: true,
        },
        {
          name: 'Candidature',
          selector: (row: IAppeal) => {
            const relatedApplications = applications.filter((application) => application.appeal_id === row._id);
            return relatedApplications.length;
          },
          sortable: true,
        },
        {
          name: 'Elimina',
          button: true,
          cell: (row: IAppeal) => (
            <button onClick={() => onDeleteClick(row)} className="red-button">
              <span className="flex flex-row items-center gap-2 ">
                <FaTrashAlt />
                {/* <p>Elimina</p> */}
              </span>
            </button>
          ),
        },
      ];
      setAppealsColumns(parsedAppealsColumns);
    }
  }, [residences, appeals, applications, onDeleteClick, onRowClick]);

  return { appealsColumns };
};

export default useAppealsColumns;
