import { IAppeal, ICreateAppeal, IDeleteAppeal } from "types/api/appeals"
import baseApi from "./baseApi"

export async function getResidencesFn() {
  const response = await baseApi.get("/backoffice/residences")
  return response.data
}

export async function getAppealsFn(): Promise<IAppeal[]> {
  const response = await baseApi.get("/backoffice/residences/appeals")
  return response.data
}

export async function createAppealFn(createAppealBody: ICreateAppeal): Promise<IAppeal> {
  const response = await baseApi.post("/backoffice/residences/appeals/appeal", createAppealBody)
  return response.data
}

export async function deleteAppealFn({ id }: IDeleteAppeal): Promise<IAppeal> {
  const response = await baseApi.delete(`/backoffice/residences/appeals/${id}`)
  return response.data
}
