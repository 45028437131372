import DataTableBase from "components/Shared/Table/Table"
import React, { useCallback, useMemo } from "react"
import { TableColumn } from "react-data-table-component"
import { FaPlus, FaUsers } from "react-icons/fa"

import { useSearchParams } from "react-router-dom"
import { IUser } from "types/api/users"
import { useUsersQuery } from "features/queries"
import NoDataComponent from "components/NoDataComponent"
import SearchBar from "components/Input/SearchBar"

enum UsersTableFilters {
  TYPE = "type",
  SEARCH = "search",
}

interface IUsersTable {
  columns: TableColumn<IUser>[]
  openNewUser: () => void
  openUserDetail: (user: IUser) => void
}

const UsersTable: React.FC<IUsersTable> = ({ columns, openNewUser, openUserDetail }) => {
  const [search, setSearch] = useSearchParams()

  const { data: users = [], isError } = useUsersQuery()

  const filterUserByText = useCallback((user: IUser, text: string) => {
    const parsedSearchText = text.toLowerCase().trim()
    if (parsedSearchText === "" || user.email.includes(parsedSearchText)) return true
    else return false
  }, [])

  const filterUserByType = useCallback((user: IUser, type: string) => {
    switch (type) {
      case "normal":
        return !user.isAdmin
      case "admin":
        return user.isAdmin
      case "residenceAdmin":
        return user.isAdmin && user.adminPermissions !== "*"
      case "generalAdmin":
        return user.isAdmin && user.adminPermissions === "*"
      default:
        return false
    }
  }, [])

  const updateFilterSearchParams = useCallback(
    (param: UsersTableFilters, value: string) => {
      if (value !== "*") search.set(param, value)
      else search.delete(param)

      setSearch(search)
    },
    [search, setSearch]
  )

  const filteredUsers = useMemo(() => {
    const isFilteredByType = search.get(UsersTableFilters.TYPE)
    const isFilteredByText = search.get(UsersTableFilters.SEARCH)

    return users.filter((user) => {
      const typeFilter = !isFilteredByType || filterUserByType(user, isFilteredByType)
      const searchFilter = !isFilteredByText || filterUserByText(user, isFilteredByText)

      return typeFilter && searchFilter
    })
  }, [search, filterUserByText, filterUserByType, users])

  const onSearchChange = useCallback(
    (value: string) => updateFilterSearchParams(UsersTableFilters.SEARCH, value),
    [updateFilterSearchParams]
  )

  const filterByType = useMemo(() => {
    const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
      updateFilterSearchParams(UsersTableFilters.TYPE, e.target.value)
    return (
      <div className="w-full self-start">
        <label className="form-label" htmlFor="filterByType">
          Filtra per Tipo
        </label>

        <select value={search.get("type") || "*"} onChange={onSelectChange} className="form-input" id="filterByType">
          <option value="*">Tutti gli utenti</option>
          {[
            { name: "Normali", value: "normal" },
            { name: "Amministratori", value: "admin" },
            { name: "Amministratore di residenza", value: "residenceAdmin" },
            { name: "Amministratore generale", value: "generalAdmin" },
          ].map((userType, idx) => (
            <option key={idx} value={userType.value}>
              {userType.name}
            </option>
          ))}
        </select>
      </div>
    )
  }, [search, updateFilterSearchParams])

  const subHeader = useMemo(() => {
    console.log(search)
    return (
      <div className="w-full  flex flex-col lg:flex-row items-center justify-start mt-4 gap-8">
        <div className="w-full md:w-3/12">{filterByType}</div>
      </div>
    )
  }, [search, filterByType])

  const userActions = useMemo(
    () => (
      <div className="w-full flex flex-col lg:flex-row items-center justify-between mt-4 lg:mt-0 gap-8">
        <SearchBar
          onChange={onSearchChange}
          placeholder="Digita per cercare un utente..."
          value={search.get(UsersTableFilters.SEARCH) || ""}
        />
        <button
          className="w-full md:w-4/12 bluemazza-button flex flex-row justify-center items-center gap-4 text-sm"
          onClick={openNewUser}
        >
          <FaPlus /> <p>Nuovo Amministratore</p>{" "}
        </button>
      </div>
    ),
    [openNewUser, onSearchChange, search]
  )

  return (
    <>
      <DataTableBase
        title={
          <div className="flex flex-row items-center justify-start gap-4 text-3xl font-bold">
            <FaUsers />
            <h3>Utenti</h3>
          </div>
        }
        actions={userActions}
        subHeader
        subHeaderComponent={subHeader}
        noDataComponent={
          <NoDataComponent
            isError={isError}
            title="Nessun utente trovato"
            description="C&#8203;è stato un errore nel recupero degli utenti"
          />
        }
        columns={columns}
        data={filteredUsers}
        pagination
        onRowClicked={openUserDetail}
        pointerOnHover
        highlightOnHover
      />
    </>
  )
}

export default UsersTable
