import { useMutation, useQueryClient } from "@tanstack/react-query"
import Modal from "components/Shared/Modal/Modal"
import React, { useCallback } from "react"
import { FaTrashAlt } from "react-icons/fa"
import { IApplication } from "types/api/applications/applications"

import { deleteApplicationFn } from "api/applicationsApi"
import sendNotification from "utils/notifications"
import { ServerStateKeysEnum } from "features/queries"

interface IDeleteApplicationDialog {
  isOpen: boolean
  onClose: () => void
  application: IApplication
  closeApplicationDetail: () => void
}

const DeleteApplicationDialog: React.FC<IDeleteApplicationDialog> = ({
  isOpen,
  onClose,
  application,
  closeApplicationDetail,
}) => {
  const queryClient = useQueryClient()

  const onDeleteApplicationSuccess = async () => {
    await queryClient.cancelQueries([ServerStateKeysEnum.APPLICATIONS])
    const previousValue = queryClient.getQueryData<IApplication[]>([ServerStateKeysEnum.APPLICATIONS]) || []

    const currentApplicationIndex = previousValue.findIndex((app) => app._id === application._id)
    if (currentApplicationIndex !== -1) {
      const updatedArray = [...previousValue]
      updatedArray.splice(currentApplicationIndex, 1)
      queryClient.setQueryData([ServerStateKeysEnum.APPLICATIONS], updatedArray)
    }
    sendNotification("Candidatura rimossa", "", "success", true, 5000)
    closeApplicationDetail()
  }

  const onDeleteApplicationError = () =>
    sendNotification("Impossibile rimuovere la candidatura", "", "error", true, 5000)

  const { mutate: deleteApplication } = useMutation(deleteApplicationFn, {
    onError: onDeleteApplicationError,
    onSuccess: onDeleteApplicationSuccess,
  })

  const onDeleteClick = useCallback(() => {
    deleteApplication({ id: application._id })
  }, [application, deleteApplication])

  const modalTitle = "Conferma rimozione"
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={modalTitle} type="danger">
      <div className=" p-4 flex flex-col justify-between items-start gap-2 w-full">
        <p className="text-lg font-bold"> Sei sicuro di voler eliminare la candidatura selezionata? </p>
        <p className="text-md font-medium">
          Tutti i dati relativi all&#8203;utente verranno mantenuti, ma la candidatura e i relativi allegati verrano
          rimossi in via definitiva.
        </p>
        <div className="w-full flex flex-row items-center justify-between mt-4">
          <button onClick={onClose} className="bluemazza-button">
            Annulla
          </button>
          <button onClick={onDeleteClick} className="red-button">
            <span className="flex flex-row items-center gap-2">
              <FaTrashAlt />
              <p>Elimina</p>
            </span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteApplicationDialog
