import React, { useCallback, useMemo, useState } from 'react';

import { IApplication } from 'types/api/applications/applications';
import ApplicationDetailDialog from 'components/Applications/ApplicationDetailDialog/ApplicationDetailDialog';
import ApplicationsTable from 'components/Applications/ApplicationsTable/ApplicationsTable';
import useApplicationsColumns from 'hooks/useApplicationsColumns';
import { useApplicationsQuery } from 'features/queries';

export default function Applications() {
  const { data: applications = [] } = useApplicationsQuery();

  //in order to automatically update selectedApplication when it changes (ex. after an update)
  const [selectedApplicationId, setSelectedApplicationId] = useState<string>();
  const selectedApplication = useMemo(() => {
    return applications?.find((app) => app._id === selectedApplicationId);
  }, [applications, selectedApplicationId]);

  const openApplicationDetail = useCallback(
    (application: IApplication) => setSelectedApplicationId(application._id),
    []
  );
  const closeApplicationDetail = useCallback(() => setSelectedApplicationId(undefined), []);

  const { applicationsColumns } = useApplicationsColumns({ onRowClick: openApplicationDetail });

  return (
    <>
      {selectedApplication && (
        <ApplicationDetailDialog isOpen={true} onClose={closeApplicationDetail} application={selectedApplication} />
      )}
      <ApplicationsTable onRowClick={openApplicationDetail} applicationsColumns={applicationsColumns} />
    </>
  );
}
