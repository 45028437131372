import Modal from "components/Shared/Modal/Modal"
import { useAppealsQuery, useResidencesQuery, useUserProfileQuery, useUsersQuery } from "features/queries"
import React, { useCallback, useMemo, useState } from "react"
import { IApplication } from "types/api/applications/applications"
import sendNotification from "utils/notifications"
import EditModeApplicationDetailContent from "./EditMode/EditModeApplicationDetailContent/EditModeApplicationDetailContent"
import EditModeApplicationDetailTitle from "./EditMode/EditModeApplicationDetailTitle/EditModeApplicationDetailTitle"
import ViewModeApplicationDetailTitle from "./ViewMode/ViewModeApplicationDetailTitle/ViewModeApplicationDetailTitle"
import ViewModeApplicationDetailContent from "./ViewMode/ViewModelApplicationDetailContent/ViewModeApplicationDetailContent"

interface IApplicationDetailDialog {
  isOpen: boolean
  onClose: () => void
  application: IApplication
}
const ApplicationDetailDialog: React.FC<IApplicationDetailDialog> = ({ isOpen, onClose, application }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const openEditMode = useCallback(() => setIsEditMode(true), [])
  const closeEditMode = useCallback(() => setIsEditMode(false), [])

  const { data: users = [] } = useUsersQuery()
  const { data: appeals = [] } = useAppealsQuery()
  const { data: residences = [] } = useResidencesQuery()
  const applicaionUser = useMemo(() => users.find((user) => user._id === application.user_id), [users, application])
  const applicationAppeal = useMemo(
    () => appeals.find((appeal) => appeal._id === application.appeal_id),
    [appeals, application]
  )
  const appealResidence = useMemo(
    () => residences.find((residence) => residence._id === applicationAppeal?.residence_id),
    [residences, applicationAppeal]
  )

  const onUserProfileError = () => sendNotification("Impossibile recuperare il profilo utente", "", "error", true, 5000)

  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    isError: isUserProfileError,
  } = useUserProfileQuery(application.user_id, {
    onError: onUserProfileError,
  })

  const ApplicationDetailTitle = useCallback(() => {
    if (isEditMode) return <EditModeApplicationDetailTitle userProfile={userProfile} closeEditMode={closeEditMode} />
    return (
      <ViewModeApplicationDetailTitle application={application} userProfile={userProfile} openEditMode={openEditMode} />
    )
  }, [isEditMode, application, userProfile, closeEditMode, openEditMode])

  const ApplicationDetailContent = useCallback(() => {
    if (isEditMode)
      return (
        <EditModeApplicationDetailContent
          application={application}
          applicationAppeal={applicationAppeal}
          applicationResidence={appealResidence}
          onClose={onClose}
        />
      )
    return (
      <ViewModeApplicationDetailContent
        application={application}
        applicationUser={applicaionUser}
        applicationAppeal={applicationAppeal}
        appealResidence={appealResidence}
        userProfile={userProfile}
        isUserProfileLoading={isUserProfileLoading}
        isUserProfileError={isUserProfileError}
      />
    )
  }, [
    isEditMode,
    application,
    applicaionUser,
    applicationAppeal,
    appealResidence,
    userProfile,
    isUserProfileLoading,
    isUserProfileError,
    onClose,
  ])

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} title={ApplicationDetailTitle()}>
      <div className="inline-block w-full overflow-visible text-left align-middle transition-all transform bg-white shadow-xl rounded">
        {ApplicationDetailContent()}
      </div>
    </Modal>
  )
}

export default ApplicationDetailDialog
