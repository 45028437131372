import { ILoginResponse } from 'types/api/auth';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getUserInCookie = () => cookies.get<ILoginResponse | undefined>('userSession');

export const setUserInCookie = (user: ILoginResponse) => cookies.set('userSession', user, { path: '/' });

export const removeUserInCookie = () => cookies.remove('userSession', { path: '/' });
