import React, { useCallback, useEffect, useState } from 'react';

import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { ScaleLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { Alert } from '../../components/Shared/Alert/Alert';
import { useMutation } from '@tanstack/react-query';
import { activateUserFn } from 'api/authApi';
import { ROUTES } from 'appRoutes';

interface IActivateAccountForm {
  password: string;
  passwordConfirmation: string;
}
const ActivateAccount: React.FC = () => {
  const { userId, verificationCode } = useParams();
  const navigate = useNavigate();

  const { mutate: activateAccountRequest, isLoading, isError, isSuccess } = useMutation(activateUserFn);

  const handleActivateAccount = useCallback(
    ({ password, passwordConfirmation }: IActivateAccountForm) => {
      if (userId && verificationCode)
        activateAccountRequest({ id: userId, verificationCode, password, passwordConfirmation });
    },
    [userId, verificationCode, activateAccountRequest]
  );

  // Page guard: userId and verificationCode mandatory
  useEffect(() => {
    if (!(userId && verificationCode)) navigate(ROUTES.AUTH_LOGIN);
  }, [userId, verificationCode, navigate]);

  if (!(userId && verificationCode)) return <></>;

  return (
    <div className="w-full flex flex-col items-center justify-center py-8">
      <div className="w-8/12 md:w-2/12 py-8 ">
        <img
          src="/images/logo.png"
          className="transition ease duration-300 cursor-pointer object-contain hover:scale-110"
          alt="cm-logo"
        />
      </div>
      <div className="animate-fade-in-right w-full md:w-4/12 shadow rounded">
        <h3 className="p-4 text-white text-xl font-bold w-full bg-bluemazza text-center underline rounded">
          Attiva il tuo account
        </h3>
        {isError && (
          <div className="m-4">
            <Alert
              status="WARNING"
              title="Attivazione fallita"
              description="Impossibile attivare il tuo account. Potrebbe essere già attivo o potresti aver cliccato su un link non valido"
            />
          </div>
        )}

        <ActivateAccountContent
          userId={userId}
          verificationCode={verificationCode}
          handleActivateAccount={handleActivateAccount}
          isSuccess={isSuccess}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

interface IActivateAccountContent {
  userId: string;
  verificationCode: string;
  handleActivateAccount: (activateBody: IActivateAccountForm) => void;
  isSuccess: boolean;
  isLoading: boolean;
}
const ActivateAccountContent: React.FC<IActivateAccountContent> = ({ handleActivateAccount, isSuccess, isLoading }) => {
  const navigate = useNavigate();

  const navigateToLogin = useCallback(() => navigate(ROUTES.AUTH_LOGIN), [navigate]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IActivateAccountForm>({
    mode: 'all',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

  const watchPassword = watch('password');

  const toggleShowPassword = useCallback(() => setShowPassword((pass) => !pass), []);
  const toggleShowPasswordConfirmation = useCallback(() => setShowPasswordConfirmation((pass) => !pass), []);

  const onSubmit = handleSubmit((data) => {
    const { password, passwordConfirmation } = data;
    handleActivateAccount({
      password,
      passwordConfirmation,
    });
  });

  if (isSuccess)
    return (
      <div className="m-4 flex flex-col items-center">
        <Alert
          status="SUCCESS"
          title="Account attivato"
          description="Il tuo account è stato attivato con successo. Puoi procedere al login"
        />
        <button onClick={() => navigate(ROUTES.AUTH_LOGIN)} className="bluemazza-button mt-4" type="button">
          Vai al login
        </button>
      </div>
    );

  return (
    <form onSubmit={onSubmit} className="p-4">
      <div className="w-full mb-4">
        <label className="form-label" htmlFor="password">
          Password
        </label>
        <div className="relative block mb-3 ">
          <input
            {...register('password', {
              required: true,
              minLength: {
                value: 6,
                message: 'La lunghezza minima è 6 caratteri',
              },
            })}
            className="form-input"
            id="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
          />
          <button
            type="button"
            onClick={toggleShowPassword}
            className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
          >
            {showPassword ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
          </button>
        </div>
        {errors.password && <p className="input-error">{errors.password.message}</p>}
      </div>
      <div className="w-full mb-4 ">
        <label className="form-label" htmlFor="passwordConfirmation">
          Conferma password
        </label>
        <div className="relative block mb-3 ">
          <input
            {...register('passwordConfirmation', {
              required: true,
              validate: (value) => value === watchPassword || 'Le password non combaciano',
            })}
            className="form-input"
            id="passwordConfirmation"
            type={showPasswordConfirmation ? 'text' : 'password'}
            placeholder="Conferma Password"
          />
          <button
            type="button"
            onClick={toggleShowPasswordConfirmation}
            className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
          >
            {showPasswordConfirmation ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
          </button>
        </div>
        {errors.passwordConfirmation && <p className="input-error">{errors.passwordConfirmation.message}</p>}
      </div>
      <div className="flex items-center justify-between w-full mt-4">
        <button onClick={navigateToLogin} className="bluemazza-button outline" type="button">
          {isLoading ? <ScaleLoader color="white" /> : 'Vai al login'}
        </button>

        <button disabled={isLoading || !isValid} className="bluemazza-button" type="submit">
          {isLoading ? <ScaleLoader color="white" /> : 'Attiva'}
        </button>
      </div>
    </form>
  );
};

export default ActivateAccount;
