import { Alert } from "components/Shared/Alert/Alert"
import React from "react"
import { ScaleLoader } from "react-spinners"
import { IAppeal } from "types/api/appeals"
import { IApplication } from "types/api/applications/applications"
import { UserProfileSchema } from "types/api/applications/userProfile"
import { IResidence } from "types/api/residences"
import { IUser } from "types/api/users"
import AppealDisclosure from "./AppealDisclosure/AppealDisclosure"
import RegistryDisclosure from "./RegistryDisclosure/RegistryDisclosure"
import UploadsDisclosure from "./UploadsDisclosure/UploadsDisclosure"

interface ViewModeApplicationDetailContent {
  application: IApplication
  applicationUser?: IUser
  applicationAppeal?: IAppeal
  appealResidence?: IResidence
  userProfile?: UserProfileSchema
  isUserProfileLoading: boolean
  isUserProfileError: boolean
}
const ViewModeApplicationDetailContent: React.FC<ViewModeApplicationDetailContent> = ({
  application,
  applicationUser,
  applicationAppeal,
  appealResidence,
  userProfile,
  isUserProfileError,
  isUserProfileLoading,
}) => {
  const userText = applicationUser?.email || "Utente non trovato"

  const getUserProfileBasedContent = () => {
    if (isUserProfileLoading)
      return (
        <div className="w-full flex flex-col items-center justify-center">
          <ScaleLoader color="#0064a9" />
          <h3 className="font-bold text-bluemazza text-md">Recupero profilo in corso...</h3>
        </div>
      )
    if (isUserProfileError)
      return (
        <Alert
          title="Impossibile recuperare il profilo utente"
          description="Tipicamente, questo accade quanto il candidato non ha ancora inserito alcun dato (deve ancora completare l'anagrafica)"
          status="ERROR"
        />
      )
    if (userProfile)
      return (
        <>
          <div className="w-full">
            <label className="form-label">Anagrafica</label>
            <RegistryDisclosure userProfile={userProfile} />
          </div>
          <div className="w-full">
            <label className="form-label">Allegati</label>
            <UploadsDisclosure application={application} appealResidence={appealResidence} userProfile={userProfile} />
          </div>
        </>
      )
  }

  return (
    <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
      <div className="w-full">
        <label className="form-label" htmlFor="password">
          Utente
        </label>

        <p className={`font-medium text-md mt-0 ${!applicationUser && "text-red-500 underline"}`}>{userText}</p>
      </div>
      <div className="w-full">
        <label className="form-label">Appello scelto</label>
        <AppealDisclosure application={application} appeal={applicationAppeal} residence={appealResidence} />
      </div>
      <div className="w-full h-0.5 my-2 bg-bluemazza" />
      {getUserProfileBasedContent()}
    </div>
  )
}

export default ViewModeApplicationDetailContent
