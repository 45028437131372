import { UserProfileSchema } from "types/api/applications/userProfile"
import { ICreateUserRequest, IUpdateUserRequest, IUser } from "types/api/users"
import baseApi from "./baseApi"

export async function getUsersFn(): Promise<IUser[]> {
  const response = await baseApi.get("/backoffice/users")
  return response.data
}

export async function createUserFn(createUserBody: ICreateUserRequest): Promise<IUser> {
  const response = await baseApi.post<IUser>("/backoffice/users/user", {
    ...createUserBody,
    isAdmin: true,
  })
  return response.data
}

export async function updateUserFn(updateUserBody: IUpdateUserRequest): Promise<IUser> {
  const response = await baseApi.put<IUser>("/backoffice/users/user", updateUserBody)
  return response.data
}

export async function getUserProfileFn(userId: string): Promise<UserProfileSchema> {
  const response = await baseApi.get(`/backoffice/users/${userId}/userProfile`)
  return response.data
}
