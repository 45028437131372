import SearchBar from "components/Input/SearchBar"
import DataTableBase from "components/Shared/Table/Table"
import { useAppealsQuery, useResidencesQuery } from "features/queries"
import React, { useCallback, useMemo } from "react"
import { FaClock, FaPlus } from "react-icons/fa"
import { useSearchParams } from "react-router-dom"
import { IAppeal } from "types/api/appeals"
import { FilterByResidence, FilterByYear } from "../../Filters/Filters"
import dayjs from "utils/dayjs"
import { TableColumn } from "react-data-table-component"
import NoDataComponent from "../../NoDataComponent"

interface IAppealsTable {
  columns: TableColumn<IAppeal>[]
  openNewAppeal: () => void
  openAppealDetail: (appeal: IAppeal) => void
}

enum AppealTableFilters {
  RESIDENCE = "residence_id",
  APPEAL_YEAR = "appeal_year",
  SEARCH = "search",
}

const AppealsTable: React.FC<IAppealsTable> = ({ columns, openNewAppeal, openAppealDetail }) => {
  const [search, setSearch] = useSearchParams()

  const { data: residences = [] } = useResidencesQuery()
  const { data: appeals = [], isError: isAppealsError } = useAppealsQuery()

  const filterAppealByText = useCallback(
    (appeal: IAppeal, text: string) => {
      const parsedText = text.toLowerCase().trim()
      if (parsedText === "") return true

      const residenceExist = residences.find((residence) => residence._id === appeal.residence_id)

      if (residenceExist && residenceExist.name.toLowerCase().includes(parsedText)) return true
    },
    [residences]
  )

  const filteredAppeals = useMemo(() => {
    const isFilteredByResidence = search.get(AppealTableFilters.RESIDENCE)
    const isFilteredByYear = search.get(AppealTableFilters.APPEAL_YEAR)
    const isFilteredByText = search.get(AppealTableFilters.SEARCH)

    return appeals.filter((appeal) => {
      const residenceFilter = !isFilteredByResidence || appeal.residence_id === isFilteredByResidence
      const yearFilter = !isFilteredByYear || dayjs(appeal.date).year() === parseInt(isFilteredByYear)
      const searchFilter = !isFilteredByText || filterAppealByText(appeal, isFilteredByText)

      return residenceFilter && yearFilter && searchFilter
    })
  }, [appeals, search, filterAppealByText])

  //closure in order to pass it straight without callbacks
  const updateFilterSearchParams = useCallback(
    (param: AppealTableFilters) => (value: string) => {
      if (value !== "*") search.set(param, value)
      else search.delete(param)

      setSearch(search)
    },
    [search, setSearch]
  )

  const userActions = useMemo(
    () => (
      <div className="w-full flex flex-col lg:flex-row items-end justify-between mt-4 lg:mt-0 gap-8">
        <SearchBar
          onChange={updateFilterSearchParams(AppealTableFilters.SEARCH)}
          value={search.get(AppealTableFilters.SEARCH) || ""}
          placeholder="Digita per cercare un appello..."
        />
        <button
          className="w-full md:w-4/12 bluemazza-button flex flex-row justify-center items-center gap-4 text-sm"
          onClick={openNewAppeal}
        >
          {" "}
          <FaPlus /> <p>Nuovo Appello</p>{" "}
        </button>
      </div>
    ),
    [openNewAppeal, search, updateFilterSearchParams]
  )

  const subHeader = useMemo(
    () => (
      <div className="w-full flex flex-col items-start">
        <div className="w-full flex flex-col lg:flex-row items-center justify-start mt-4 gap-8">
          <FilterByResidence
            residences={residences}
            selected={search.get(AppealTableFilters.RESIDENCE) || "*"}
            onChange={updateFilterSearchParams(AppealTableFilters.RESIDENCE)}
          />
          <FilterByYear
            appeals={appeals}
            selected={search.get(AppealTableFilters.APPEAL_YEAR) || "*"}
            onChange={updateFilterSearchParams(AppealTableFilters.APPEAL_YEAR)}
          />
        </div>
        <p className="mt-6 text-md text-black">
          <span className="font-bold underline">{filteredAppeals.length} appelli trovati</span>
        </p>
      </div>
    ),
    [search, appeals, residences, filteredAppeals, updateFilterSearchParams]
  )

  return (
    <DataTableBase
      title={
        <div className="flex flex-row items-center justify-start gap-4 text-3xl font-bold">
          <FaClock />
          <h3>Appelli</h3>
        </div>
      }
      actions={userActions}
      noDataComponent={
        <NoDataComponent
          isError={isAppealsError}
          title="Nessun appello trovato"
          description="C&#8203;è stato un errore nel recupero degli appelli"
        />
      }
      subHeader
      subHeaderComponent={subHeader}
      columns={columns}
      data={filteredAppeals}
      pagination
      onRowClicked={openAppealDetail}
      pointerOnHover
      highlightOnHover
    />
  )
}

export default AppealsTable
