import SearchBar from 'components/Input/SearchBar';
import NoDataComponent from 'components/NoDataComponent';
import DataTableBase from 'components/Shared/Table/Table';
import { useResidencesQuery } from 'features/queries';
import React, { useCallback, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FaHome } from 'react-icons/fa';
import { IResidence } from 'types/api/residences';

interface IResidencesTable {
  columns: TableColumn<IResidence>[];
  onRowClick: (residence: IResidence) => void;
}

const ResidencesTable: React.FC<IResidencesTable> = ({ columns, onRowClick }) => {
  const [searchText, setSearchText] = useState<string>('');

  const { data: residences = [], isError } = useResidencesQuery();

  const filteredResidences = useMemo(
    () =>
      residences.filter((row) => {
        const parsedSearchTerm = searchText.toLowerCase().trim();
        if (searchText === '' || row.name.toLowerCase().includes(parsedSearchTerm)) return true;
      }),
    [residences, searchText]
  );

  const onSearchChange = useCallback((value: string) => setSearchText(value), []);

  const userActions = useMemo(
    () => (
      <div className="w-full flex flex-col lg:flex-row items-center justify-between mt-4 lg:mt-0 gap-8">
        <SearchBar value={searchText} placeholder="Digita per cercare un bando..." onChange={onSearchChange} />
      </div>
    ),
    [onSearchChange, searchText]
  );

  return (
    <DataTableBase
      title={
        <div className="flex flex-row items-center justify-start gap-4 text-3xl font-bold">
          <FaHome />
          <h3>Bandi</h3>
        </div>
      }
      actions={userActions}
      noDataComponent={
        <NoDataComponent
          isError={isError}
          title="Nessun bando trovato"
          description="C&#39;è stato un errore nel recupero dei bandi"
        />
      }
      columns={columns}
      data={filteredResidences}
      pagination
      onRowClicked={onRowClick}
      pointerOnHover
      highlightOnHover
    />
  );
};

export default ResidencesTable;
