import React from "react"

interface INoDataComponent {
  isError: boolean
  title: React.ReactNode
  description: React.ReactNode
}
const NoDataComponent: React.FC<INoDataComponent> = ({ isError, title, description }) => (
  <div className="flex flex-col items-center">
    <h3 className="font-bold text-md">{title}</h3>
    {isError && <h6 className="font-medium text-red-500 underline">{description}</h6>}
  </div>
)

export default NoDataComponent
