import React from "react"
import Header from "./Header"
import Navbar from "./Navbar"

interface ILayout {
  children: React.ReactNode
}
const Layout: React.FC<ILayout> = ({ children }) => {
  return (
    <div className="flex flex-col">
      <span className="block md:hidden">
        <Header />
      </span>
      <div className="flex flex-row items-center gap-12 mt-8" style={{ minHeight: "80vh" }}>
        <Navbar />
        <div className="flex flex-col w-full self-start">{children}</div>
      </div>
    </div>
  )
}

export default Layout
