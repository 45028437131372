import { Dialog, Transition } from "@headlessui/react"
import { useQuery } from "@tanstack/react-query"
import { getApplicationsFn } from "api/applicationsApi"
import { getResidencesFn } from "api/residencesApi"
import React, { Fragment, useMemo } from "react"
import { FaEnvelopeOpenText } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "appRoutes"
import { IAppeal } from "types/api/appeals"
import dayjs from "utils/dayjs"
import { IApplication } from "types/api/applications/applications"
import { IResidence } from "types/api/residences"

interface IAppealDetailDialog {
  isOpen: boolean
  onClose: () => void
  appeal: IAppeal
}
const AppealDetailDialog: React.FC<IAppealDetailDialog> = ({ isOpen, onClose, appeal }) => {
  const navigate = useNavigate()

  const { data: residences } = useQuery<IResidence[]>(["residences"], getResidencesFn, {
    placeholderData: [],
    initialData: [],
  })

  const { data: applications } = useQuery<IApplication[]>(["applications"], getApplicationsFn, {
    placeholderData: [],
    initialData: [],
  })

  const appealResidence = useMemo(
    () => residences.find((residence) => residence._id === appeal.residence_id),
    [residences, appeal]
  )
  const appealApplications = useMemo(
    () => applications.filter((application) => application.appeal_id === appeal._id),
    [applications, appeal]
  )

  const AppealStatus = () => {
    if (dayjs(appeal.expiryDate) < dayjs()) return <p className="bg-red-500 p-2 rounded text-md font-medium">Scaduto</p>
    if (dayjs(appeal.acceptCandidacyFromDate) > dayjs())
      return <p className="bg-orange-500 p-2 rounded text-md font-medium">In partenza</p>

    return <p className="bg-green-500 p-2 rounded text-md font-medium">In corso</p>
  }

  const navigateToAppealApplications = () => navigate(`${ROUTES.APPLICATIONS}?appeal_id=${appeal._id}`)

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen mx-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md overflow-visible text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title as="h3" className="p-4 bg-bluemazza text-xl font-bold leading-6 text-white rounded-t">
                <div className="w-full flex flex-row items-center justify-between">
                  Dettagli appello
                  {AppealStatus()}
                </div>
              </Dialog.Title>

              <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
                <div className="w-full">
                  <label className="form-label" htmlFor="password">
                    Bando
                  </label>

                  <p className="font-medium text-md mt-0">{appealResidence?.name}</p>
                </div>
                <div className="w-full">
                  <label className="form-label" htmlFor="passwordConfirmation">
                    Data
                  </label>

                  <div className="w-full flex flex-row items-center justify-between">
                    <p className="font-medium text-md mt-0">{dayjs(appeal.date).format("DD/MM/YYYY")}</p>
                    <p
                      className={`${dayjs(appeal.date) < dayjs() && "text-red-500"} font-medium text-md mt-0 underline`}
                    >
                      {dayjs(appeal.date).fromNow()}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <label className="form-label" htmlFor="passwordConfirmation">
                    Scadenza
                  </label>
                  <div className="w-full flex flex-row items-center justify-between">
                    <p className="font-medium text-md mt-0">{dayjs(appeal.expiryDate).format("DD/MM/YYYY")}</p>
                    <p
                      className={`${
                        dayjs(appeal.expiryDate) < dayjs() && "text-red-500"
                      } font-medium text-md mt-0 underline`}
                    >
                      {dayjs(appeal.expiryDate).fromNow()}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <label className="form-label" htmlFor="passwordConfirmation">
                    Data di partenza
                  </label>

                  <div className="w-full flex flex-row items-center justify-between">
                    <p className="font-medium text-md mt-0">
                      {dayjs(appeal.acceptCandidacyFromDate).format("DD/MM/YYYY")}
                    </p>
                    <p className="font-medium text-md mt-0 underline">
                      {dayjs(appeal.acceptCandidacyFromDate).diff(dayjs(), "days") > 0
                        ? `Tra ${dayjs(appeal.acceptCandidacyFromDate).diff(dayjs(), "days")} giorni`
                        : `${Math.abs(dayjs(appeal.acceptCandidacyFromDate).diff(dayjs(), "days"))} giorni fa `}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <label className="form-label" htmlFor="passwordConfirmation">
                    Candidature
                  </label>

                  <p className="font-medium text-md mt-0">{appealApplications.length}</p>
                </div>
                <div className="w-full flex flex-row items-center justify-center mt-4">
                  <button
                    onClick={navigateToAppealApplications}
                    className="bluemazza-button flex flex-row items-center gap-4"
                    type="button"
                  >
                    <FaEnvelopeOpenText />
                    <p>Visualizza i candidati a questo appello</p>
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default AppealDetailDialog
