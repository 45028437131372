import React from "react"

type IStatus = "SUCCESS" | "INFO" | "ERROR" | "WARNING"
const getStatusColor = (status: IStatus) => {
  const infoStatus = {
    alert: { title: "bg-blue-500", body: "bg-blue-100" },
    alertAccent: "bg-blue-100 border-blue-500 text-blue-900",
    alertInline: "bg-blue-100 border border-blue-400 text-blue-700",
  }
  switch (status) {
    case "ERROR":
      return {
        alert: { title: "bg-red-500", body: "bg-red-100" },
        alertAccent: "bg-red-100 border-red-500 text-red-900",
        alertInline: "bg-red-100 border border-red-400 text-red-700",
      }
    case "WARNING":
      return {
        alert: { title: "bg-orange-500", body: "bg-orange-100" },
        alertAccent: "bg-orange-100 border-orange-500 text-orange-900",
        alertInline: "bg-orange-100 border border-orange-400 text-orange-700",
      }
    case "SUCCESS":
      return {
        alert: { title: "bg-green-500", body: "bg-green-100" },
        alertAccent: "bg-green-100 border-green-500 text-green-900",
        alertInline: "bg-green-100 border border-green-400 text-green-700",
      }
    case "INFO":
      return infoStatus
    default:
      return infoStatus
  }
}
interface IAlert {
  title: string
  description?: string
  component?: React.ReactNode
  status: IStatus
}
export const AlertInline: React.FC<IAlert> = ({ title, description, status }) => {
  const statusColor = getStatusColor(status).alertInline

  return (
    <div className={`w-full border ${statusColor} px-4 py-3 rounded relative`} role="alert">
      <strong className="font-bold">{title}</strong>
      <span className="block sm:inline"> {description}</span>
    </div>
  )
}
export const Alert: React.FC<IAlert> = ({ title, description, component, status }) => {
  const statusColor = getStatusColor(status).alert
  return (
    <div className="w-full flex flex-col">
      <div className={`w-full text-white text-md font-bold rounded-t px-4 py-2 ${statusColor.title}`}>{title}</div>
      <div className={`flex flex-col items-start px-4 py-2 ${statusColor.body}`}>
        {description && <p className="text-sm">{description}</p>}
        {!!component && component}
      </div>
    </div>
  )
}

export const AlertAccent: React.FC<IAlert> = ({ title, description, component, status }) => {
  const statusColor = getStatusColor(status).alertAccent
  return (
    <div
      className={`border-t-4  rounded- px-4 py-3 shadow-md 
      ${statusColor}`}
      role="alert"
    >
      <div className="flex flex-col">
        <p className="text-white text-md font-bold">{title}</p>
        <div className="flex flex-col items-start">
          {!!component && component}
          {description && <p className="text-md">{description}</p>}
        </div>
      </div>
    </div>
  )
}
